export const FINAL_EXAMINATION_FORM_TICK_LABELS = {
  crucial: {
    0: "",
    1: "",
    2: "⚠",
    3: "⚠",
  },
  hotelSpecificTasks: {
    0: "3",
    1: "2",
    2: "1",
    3: "0",
  },
  generalTasks: {
    0: "1",
    1: "0.5",
    2: "0",
    3: "0",
  },
};

export const FINAL_EXAMINATION_FORM_THUMB_LABELS = {
  0: "Ja ohne Hilfestellung",
  1: "Ja mit der Dienstanweisung",
  2: "Ja mit Hilfestellung Prüfer",
  3: "Nein auch nicht mit Hilfestellung",
};

export const INTERMEDIARY_EXAMINATION_FORM_THUMB_LABELS = {
  0: "Ja ohne Hilfestellung",
  1: "Ja mit der Dienstanweisung",
  2: "Nein auch nicht mit Hilfestellung",
};

export const ASSESSMENT_RANGE_TEXTS = {
  crucial:
    "Ein oder mehrere sicherheitsrelevante Aufgaben/Informationen konnten nicht korrekt und/oder vollständig beantwortet werden. <br/><br/>Eine weitere Einarbeitung bzw. Unterweisung ist zwingend notwendig.",
  "0-49%":
    "Der Mitarbeiter konnte auch mit Hilfe der Dienstanweisung und mit Hilfestellung des Prüfers einen Teil der geprüften Aufgaben nicht erledigen und auch keine Auskünfte geben. <br/><br/>Eine weitere Einarbeitung ist zwingend notwendig.",
  "50-75%":
    "Der Mitarbeiter konnte mit Hilfe der Dienstanweisung und Hilfestellung des Prüfers alle geprüften Aufgaben erledigen und Auskünfte geben. Der Mitarbeiter ist jedoch noch unselbstständig und unsicher in der Ausführung. <br/><br/>Eine weitere Einarbeitung wird empfohlen, da der Mitarbeiter noch zu unselbstständig arbeitet.",
  "75-89%":
    "Der Mitarbeiter konnte mit Hilfe der Dienstanweisung und kleineren Hilfestellungen des Prüfers alle geprüften Aufgaben selbstständig erledigen und Auskünfte geben. <br/><br/>Eine weitere Einarbeitung ist nicht zwingend notwendig, der Mitarbeiter sollte jedoch in der ersten Woche betreut werden.",
  "90-100%":
    "Der Mitarbeiter kann mit kleiner Hilfe der Dienstanweisung alle geprüften Aufgaben selbstständig erledigen und alle Auskünfte geben. <br/><br/>Eine weitere Einarbeitung oder Nachschulung ist nicht notwendig.",
};
