<template>
  <v-tabs
    v-model="trainingDocsTab"
    fixed-tabs
    color="blue-grey-darken-1"
    class="px-1"
  >
    <v-tab value="abnahmen">Abnahmen</v-tab>
    <v-tab value="einarbeitungslisten">Einarbeitungslisten</v-tab>
  </v-tabs>
  <v-window v-model="trainingDocsTab" disabled>
    <v-window-item value="abnahmen">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-combobox
              v-model="employeeFilter"
              :items="employeeFilterOptions"
              label="Mitarbeiter"
              item-title="name"
              multiple
              chips
              clearable
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-combobox
              v-model="hotelFilter"
              :items="hotelFilterOptions"
              label="Hotel"
              item-title="name"
              item-value="id"
              multiple
              chips
              clearable
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              v-model="monthFilter"
              :items="monthFilterOptions"
              label="Monat"
              clearable
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-combobox
              v-model="examinerFilter"
              :items="employeeFilterOptions"
              label="Abnahme durch"
              item-title="name"
              multiple
              chips
              clearable
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-combobox
              v-model="documentTypeFilter"
              :items="documentTypeFilterOptions"
              label="Dokument"
              multiple
              chips
              clearable
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-checkbox
              v-model="showUnreviewedFilter"
              label="Nur Ungeprüfte anzeigen"
              hide-details
              density="compact"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :items="trainingDocs"
              :items-per-page="50"
              :headers="headers"
            >
              <template v-slot:item.employee="{ item }">
                <router-link :to="'/Mitarbeiter/' + item.employee.id">
                  {{
                    `${item?.employee?.last_name}, ${item?.employee?.first_name}`
                  }}</router-link
                >
              </template>
              <template v-slot:item.hotel="{ item }">
                <v-chip>
                  {{ item.hotel.short_name }}
                  <v-tooltip
                    activator="parent"
                    location="right"
                    open-delay="300"
                  >
                    {{ item.hotel.name }}
                  </v-tooltip>
                </v-chip>
              </template>
              <template v-slot:item.examiner="{ item }">
                {{
                  item.examiner?.id
                    ? `${item.examiner.last_name}, ${item.examiner.first_name}`
                    : ""
                }}
              </template>
              <template v-slot:item.trainingDocument="{ item }">
                <v-chip
                  :color="
                    getDocumentChipColor(
                      item.trainingDocument,
                      item.trainingResult.training_result_grading_id,
                    )
                  "
                  label
                  @click="
                    openTrainingForm({
                      tableRow: item,
                    })
                  "
                  >{{ item.trainingDocument }}</v-chip
                >
                <v-icon v-if="item.isCompleted && !item.reviewedAt" color="red">
                  mdi-exclamation-thick
                </v-icon>
                <v-icon v-if="!item.isCompleted" color="warning">
                  mdi-progress-pencil
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-window-item>
    <v-window-item value="einarbeitungslisten">
      <EinarbeitungslistenTable />
    </v-window-item>
  </v-window>
  <v-dialog
    v-model="trainingFormsDialog.Zwischenstand.isOpen"
    transition="dialog-top-transition"
    fullscreen
  >
    <IntermediaryTestForm
      :trainee="trainingFormsDialog.employee"
      :hotel="trainingFormsDialog.hotel"
      :city="trainingFormsDialog.city"
      :training-result="trainingFormsDialog.trainingResult"
      :close-dialog="
        () => {
          trainingFormsDialog.Zwischenstand.isOpen = false;
          fetchTrainingData();
        }
      "
    ></IntermediaryTestForm>
  </v-dialog>
  <v-dialog
    v-model="trainingFormsDialog['Finale Abnahme'].isOpen"
    transition="dialog-top-transition"
    fullscreen
  >
    <FinalTestForm
      :trainee="trainingFormsDialog.employee"
      :hotel="trainingFormsDialog.hotel"
      :city="trainingFormsDialog.city"
      :training-result="trainingFormsDialog.trainingResult"
      :close-dialog="
        () => {
          trainingFormsDialog['Finale Abnahme'].isOpen = false;
          fetchTrainingData();
        }
      "
    ></FinalTestForm>
  </v-dialog>
</template>

<script setup>
import { onMounted, ref, watch, reactive } from "vue";
import { supabase } from "@/supabase";
import { parse, isSameMonth } from "date-fns";
import { de } from "date-fns/locale";
import EinarbeitungslistenTable from "../Trainings/EinarbeitungslistenTable.vue";
import IntermediaryTestForm from "../Trainings/IntermediaryTestForm.vue";
import FinalTestForm from "../Trainings/FinalTestForm.vue";
import getFormattedShiftDate from "@/utils/getFormattedShiftDate";

let trainingDocsTab = ref("abnahmen");

let headers = ref([
  {
    title: "Mitarbeiter",
    key: "employee",
    value: "employee",
    sort: (a, b) => a.last_name.localeCompare(b.last_name),
  },
  { title: "Stadt", key: "city", value: "city" },
  { title: "Hotel", key: "hotel", value: "hotel" },
  { title: "Schicht", key: "date", value: "date" },
  { title: "Abnahme durch", key: "examiner", value: "examiner" },
  { title: "Dokument", key: "trainingDocument", value: "trainingDocument" },
]);

let trainingFormsDialog = reactive({
  employee: null,
  hotel: null,
  city: null,
  isSenior: true,
  Einarbeitungsliste: {
    isOpen: false,
  },
  Zwischenstand: {
    isOpen: false,
  },
  ["Finale Abnahme"]: {
    isOpen: false,
  },
});

// Table data
let trainingDocs = ref([]);

// Filter refs
const employeeFilter = ref([]);
const hotelFilter = ref([]);
const monthFilter = ref(null);
const documentTypeFilter = ref([]);
const showUnreviewedFilter = ref(true);
const examinerFilter = ref([]);

const monthFilterOptions = ref([]);
const documentTypeFilterOptions = ref(["Zwischenstand", "Finale Abnahme"]);
const hotelFilterOptions = ref([]);
const employeeFilterOptions = ref([]);

const getDocumentChipColor = (trainingDocument, resultGradingId) => {
  if (trainingDocument !== "Finale Abnahme") {
    return "grey";
  }

  switch (resultGradingId) {
    case 1:
    case 2:
      return "success";
    case 3:
      return "warning";
    case 4:
    case 5:
      return "error";
    default:
      return "grey";
  }
};

/**
 * Function to prepare table data
 */
const prepareTableData = (dbTrainingResultData) => {
  const trainingData = [];

  dbTrainingResultData.forEach((trainingResult) => {
    const isIntermediaryStarted = trainingResult.started_intermediary_at;
    const intermediaryDocType =
      documentTypeFilter.value.length === 0 ||
      documentTypeFilter.value.includes("Zwischenstand");
    const intermediaryMonth =
      !monthFilter.value ||
      isWithinMonth(trainingResult.started_intermediary_at, monthFilter.value);
    const shouldDisplayUnreviewedIntermediary =
      (showUnreviewedFilter.value &&
        !trainingResult.reviewed_intermediary_at) ||
      !showUnreviewedFilter.value;
    const isIntermediaryExaminer =
      examinerFilter.value.length === 0 ||
      examinerFilter.value
        .map((examiner) => examiner.id)
        .includes(trainingResult.intermediary_examiner_id);

    if (
      isIntermediaryStarted &&
      intermediaryDocType &&
      intermediaryMonth &&
      shouldDisplayUnreviewedIntermediary &&
      isIntermediaryExaminer
    ) {
      trainingData.push({
        employee: trainingResult.employee,
        city: trainingResult.hotel.city.city,
        hotel: trainingResult.hotel,
        examiner:
          employeeFilterOptions.value.find(
            (employee) =>
              employee.id === trainingResult.intermediary_examiner_id,
          ) ?? {},
        date: getFormattedShiftDate(trainingResult.started_intermediary_at),
        isCompleted: trainingResult.completed_intermediary_at !== null,
        trainingDocument: "Zwischenstand",
        trainingResult,
        reviewedAt: trainingResult.reviewed_intermediary_at,
      });
    }

    const isFinalStarted = trainingResult.started_final_at;
    const finalDocType =
      documentTypeFilter.value.length === 0 ||
      documentTypeFilter.value.includes("Finale Abnahme");
    const finalMonth =
      !monthFilter.value ||
      isWithinMonth(trainingResult.started_final_at, monthFilter.value);
    const shouldDisplayUnreviewedFinal =
      (showUnreviewedFilter.value && !trainingResult.reviewed_final_at) ||
      !showUnreviewedFilter.value;

    const isFinalExaminer =
      examinerFilter.value.length === 0 ||
      examinerFilter.value
        .map((examiner) => examiner.id)
        .includes(trainingResult.final_examiner_id);

    if (
      isFinalStarted &&
      finalDocType &&
      finalMonth &&
      shouldDisplayUnreviewedFinal &&
      isFinalExaminer
    ) {
      trainingData.push({
        employee: trainingResult.employee,
        city: trainingResult.hotel.city.city,
        hotel: trainingResult.hotel,
        date: getFormattedShiftDate(trainingResult.started_final_at),
        isCompleted: trainingResult.completed_final_at !== null,
        examiner:
          employeeFilterOptions.value.find(
            (employee) => employee.id === trainingResult.final_examiner_id,
          ) ?? {},
        trainingDocument: "Finale Abnahme",
        trainingResult,
        reviewedAt: trainingResult.reviewed_final_at,
      });
    }
  });

  trainingDocs.value = trainingData;
};

// Helper function to check month using date-fns
const isWithinMonth = (dateString, month) => {
  const parsedMonth = parse(month, "LLLL yyyy", new Date(), { locale: de });
  const date = new Date(dateString);
  return isSameMonth(date, parsedMonth);
};

/**
 * Function to fetch training data based on filters
 */
const fetchTrainingData = async () => {
  const filters = {
    employee: employeeFilter.value.map((e) => e.id),
    hotel: hotelFilter.value.map((h) => h.id),
    month: monthFilter.value,
    examiner: examinerFilter.value.map((e) => e.id),
    trainingDocument: documentTypeFilter.value,
    showUnreviewed: showUnreviewedFilter.value,
  };

  let dataFetchQuery = supabase
    .from("training_hotel_results")
    .select(
      "*, employee:employees!training_hotel_result_trainee_id_fkey(id, last_name, first_name, name), hotel:hotels!inner(id, name, short_name, city:cities!inner(city))",
    );

  if (filters.employee.length > 0) {
    dataFetchQuery = dataFetchQuery.in("trainee_id", filters.employee);
  }

  if (filters.hotel.length > 0) {
    dataFetchQuery = dataFetchQuery.in("hotel.id", filters.hotel);
  }

  if (filters.showUnreviewed) {
    dataFetchQuery = dataFetchQuery.or(
      "reviewed_intermediary_at.is.null,reviewed_final_at.is.null",
    );
  }

  if (filters.examiner.length > 0) {
    dataFetchQuery = dataFetchQuery.or(
      `intermediary_examiner_id.in.(${filters.examiner}),final_examiner_id.in.(${filters.examiner})`,
    );
  }

  // Filter by time (Monthname yyyy)
  if (filters.month) {
    const parsedMonth = parse(filters.month, "LLLL yyyy", new Date(), {
      locale: de,
    });
    const monthStart = new Date(
      parsedMonth.getFullYear(),
      parsedMonth.getMonth(),
      1,
    );
    const monthEnd = new Date(
      parsedMonth.getFullYear(),
      parsedMonth.getMonth() + 1,
      1,
    );

    dataFetchQuery = dataFetchQuery.or(
      `started_intermediary_at.gte.${monthStart.toISOString()},started_final_at.gte.${monthStart.toISOString()}`,
    );

    dataFetchQuery = dataFetchQuery.or(
      `started_intermediary_at.lte.${monthEnd.toISOString()},started_final_at.lte.${monthEnd.toISOString()}`,
    );
  }

  const { data, error } = await dataFetchQuery;

  if (error) {
    console.error(error);
    // TODO: handle error
    return;
  }

  prepareTableData(data);
};

/**
 * Function to fetch all filter options independently
 */
const fetchFilterOptions = async () => {
  // Fetch Employees
  const { data: employees, error: empError } = await supabase
    .from("employees")
    .select("id, last_name, first_name, name")
    .order("last_name", { ascending: true });

  if (empError) {
    // TODO: handle error
    console.error(empError);
    return;
  }
  employeeFilterOptions.value = employees;

  // Fetch Hotels
  const { data: hotels, error: hotelError } = await supabase
    .from("hotels")
    .select("id, name, short_name, city:cities(city)")
    .order("name", { ascending: true });

  if (hotelError) {
    console.error(hotelError);
    return;
  }
  hotelFilterOptions.value = hotels;

  // Fetch Months
  const { data: trainingResults, error: trError } = await supabase
    .from("training_hotel_results")
    .select("started_intermediary_at, started_final_at");

  if (trError) {
    console.error(trError);
    return;
  }

  const months = new Set();
  trainingResults.forEach((tr) => {
    if (tr.started_intermediary_at) {
      months.add(
        new Date(tr.started_intermediary_at).toLocaleString("de", {
          month: "long",
          year: "numeric",
        }),
      );
    }
    if (tr.started_final_at) {
      months.add(
        new Date(tr.started_final_at).toLocaleString("de", {
          month: "long",
          year: "numeric",
        }),
      );
    }
  });
  monthFilterOptions.value = Array.from(months).sort(
    (a, b) => new Date(b) - new Date(a),
  );
};

function openTrainingForm({ tableRow }) {
  trainingFormsDialog.employee = tableRow.employee;
  trainingFormsDialog.hotel = tableRow.hotel;
  trainingFormsDialog.city = tableRow.city;
  trainingFormsDialog.trainingResult = tableRow.trainingResult;
  trainingFormsDialog[tableRow.trainingDocument].isOpen = true;
}

onMounted(async () => {
  await fetchFilterOptions();
  await fetchTrainingData();
});

// Watchers to fetch data on filter change
watch(
  [
    employeeFilter,
    hotelFilter,
    monthFilter,
    examinerFilter,
    documentTypeFilter,
    showUnreviewedFilter,
  ],
  () => {
    fetchTrainingData();
  },
);
</script>

<style scoped></style>
