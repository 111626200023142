import { defineStore } from "pinia";

export const useProgressFormStore = defineStore("progressForm", {
  state: () => ({
    taskTrainingForm: [],
    isCompleted: false,
    trainingResultsId: null,
    trainingShiftDates: [],
    updatedTasks: new Map(),
    refetchDataAfterSave: false,
    isSubmitted: false,
    feedbackForm: {
      systemKnowledge: null,
      receptionTasks: {
        selected: [],
        quantities: {
          checkIn: null,
          checkOut: null,
        },
      },
      hotelSpecificSafetyTopics: null,
      challenges: null,
      serviceInstructions: null,
      serviceInstructionsText: null,
      workBehavior: {
        onTime: null,
        dressCode: null,
        dressCodeComment: null,
        initiative: null,
        attention: null,
        motivation: null,
        guestBehavior: null,
        guestBehaviorTested: null,
        colleagueBehavior: null,
        pcKnowledge: null,
        pcKnowledgeTested: null,
      },
      daysNeeded: null,
    },
  }),
  actions: {
    setTaskTrainingForm(newForm) {
      this.taskTrainingForm = newForm;
    },
    setIsCompleted(status) {
      this.isCompleted = status;
    },
    setTrainingResultsId(id) {
      this.trainingResultsId = id;
    },
    setTrainingShiftDates(dates) {
      this.trainingShiftDates = dates;
    },
    setFeedbackForm(form) {
      this.feedbackForm = form;
    },
  },
  getters: {
    getTaskTrainingForm: (state) => state.taskTrainingForm,
    getIsCompleted: (state) => state.isCompleted,
    getTrainingResultsId: (state) => state.trainingResultsId,
    getTrainingShiftDates: (state) => state.trainingShiftDates,
  },
});
