<template>
  <v-row class="ma-4">
    <v-col cols="12">
      <v-row>
        <v-col
          class="mb-n6"
          xxl="2"
          xl="2"
          lg="4"
          md="4"
          sm="12"
          xs="12"
          cols="12"
        >
          <v-autocomplete
            density="compact"
            clearable
            label="Hotel"
            :items="hotels"
            item-title="name"
            item-value="id"
            v-model="hotelFilter"
            @update:modelValue="getEntries('new')"
          ></v-autocomplete>
        </v-col>
        <v-col
          class="mb-n6"
          xxl="2"
          xl="2"
          lg="4"
          md="4"
          sm="12"
          xs="12"
          cols="12"
        >
          <v-text-field
            density="compact"
            v-model="search"
            label="Freitextsuche"
            @input="getEntries('new')"
          ></v-text-field>
        </v-col>
        <v-col
          class="mb-n6"
          xxl="2"
          xl="2"
          lg="4"
          md="4"
          sm="12"
          xs="12"
          cols="12"
          align="center"
        >
          <v-btn-toggle v-model="statusFilter" mandatory v-if="false">
            <v-btn size="x-small" value="offen" @click="getEntries('new')"
              >Offen</v-btn
            >
            <v-btn size="x-small" value="gelöst" @click="getEntries('new')"
              >Gelöst</v-btn
            >
            <v-btn size="x-small" value="sonstiges" @click="getEntries('new')"
              >Sonstiges</v-btn
            >
            <v-btn size="x-small" value="all" @click="getEntries('new')"
              >Alle</v-btn
            >
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-col cols="12" align="center">
        <v-btn
          @click="
            entryInput.dialog = true;
            entryInput.task_listeners = [];
            entryInput.data = {
              hotel_id: null,
              employee_id: null,
              hotel: {},
              problem: null,
              solution: null,
              status: 'offen',
              date: new Date().toISOString().substr(0, 10),
            };
          "
          color="success"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" align="center"> {{ pagination.count }} Einträge </v-col>
      <v-pagination
        :length="pagination.length"
        @click="getEntries()"
        v-model="pagination.page"
      ></v-pagination>
      <v-row class="mx-1 my-1">
        <template v-for="(entry, index) in entries">
          <v-col class="my-n2" cols="12">
            <v-card
              @click="
                entryInput.data = entry;
                commentMode.entry = entry;
                entryInput.task_listeners = [];
                entryInput.dialog = true;
              "
            >
              <v-card-title class="bg-blue-grey">
                {{ entry.hotel?.name }}
                <v-chip size="small" variant="elevated">{{
                  entry.shortStatus
                }}</v-chip>
              </v-card-title>
              <v-card-text class="mt-2">
                <v-row>
                  <v-col xxl="2" xl="2" lg="4" md="4" sm="12" xs="12">
                    <v-chip size="small"
                      ><v-icon>mdi-domain</v-icon
                      >{{ entry.hotel?.name }}</v-chip
                    >
                    <v-chip size="small"
                      ><v-icon>mdi-calendar</v-icon
                      >{{ entry.formattedDate }}</v-chip
                    >
                    <v-chip size="small"
                      ><v-icon>mdi-account</v-icon
                      >{{
                        entry.employee ? entry.employee : entry.employee_name
                      }}</v-chip
                    >
                    <v-chip size="small"
                      ><v-icon>mdi-account-tie</v-icon
                      >{{ entry.creator }}</v-chip
                    >
                    <v-badge
                      :content="entry.hotel_gold_on_duty_comments.length"
                      color="red"
                      v-if="entry.hotel_gold_on_duty_comments.length > 0"
                    >
                      <v-icon
                        icon="mdi-chat"
                        size="x-large"
                        color="teal-darken-2"
                      ></v-icon>
                    </v-badge>
                  </v-col>
                  <v-col xxl="5" xl="5" lg="4" md="4" sm="12" xs="12" cols="12">
                    <v-alert type="warning">{{ entry.problem }}</v-alert>
                  </v-col>
                  <v-col xxl="5" xl="5" lg="4" md="4" sm="12" xs="12" cols="12">
                    <v-alert type="success">{{ entry.solution }}</v-alert>
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="entry.comment && entry.tasks.length == 0"
                  >
                    {{ entry.comment }}
                  </v-col>
                  <v-col cols="12" v-if="entry.tasks.length > 0">
                    <v-card variant="outlined">
                      <v-card-text v-for="task in entry.tasks">
                        <div class="text-h6">
                          Aufgabe
                          <v-chip
                            :key="task.id"
                            size="small"
                            variant="elevated"
                            :color="task.is_completed ? 'success' : 'warning'"
                          >
                            <span v-if="task.is_completed">Gelöst</span>
                            <span v-else>Offen</span>
                          </v-chip>
                        </div>
                        <v-select
                          multiple
                          chips
                          readonly
                          label="Zugewiesen an"
                          v-model="task.listeners"
                          :items="users"
                          item-value="id"
                          item-title="name"
                        >
                        </v-select>
                        <v-col v-if="entry.comment" cols="6"
                          >{{ entry.comment }}
                        </v-col>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-col>
  </v-row>
  <v-dialog v-model="entryInput.dialog" width="700">
    <v-card color="grey-lighten-4">
      <v-card-title class="bg-teal-darken-2">
        <v-row>
          <v-col align="left"> Eintrag </v-col>
          <v-col align="right">
            <v-btn
              @click="entryInput.dialog = false"
              flat
              color="teal-darken-2"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-autocomplete
          v-if="!entryInput.data.id"
          v-model="entryInput.shift"
          variant="solo"
          label="Heutige Schichten"
          :items="todayShifts"
          item-title="label"
          return-object
          @update:modelValue="setShift()"
        ></v-autocomplete>
        <v-text-field
          variant="solo"
          v-model="entryInput.data.hotel.name"
          label="Hotel"
        ></v-text-field>
        <v-text-field
          variant="solo"
          v-model="entryInput.data.employee"
          label="Mitarbeiter"
        ></v-text-field>
        <v-textarea
          variant="solo"
          append-inner-icon="mdi-help"
          v-model="entryInput.data.problem"
          label="Problem"
          :readonly="
            entryInput.data.creator_id != userEmployeeId && entryInput.data.id
          "
        ></v-textarea>
        <v-textarea
          variant="solo"
          append-inner-icon="mdi-thumb-up"
          v-model="entryInput.data.solution"
          label="Lösung"
          :readonly="
            entryInput.data.creator_id != userEmployeeId && entryInput.data.id
          "
        ></v-textarea>
        <v-textarea
          variant="solo"
          v-if="entryInput.data.comment"
          append-inner-icon="mdi-thumb-up"
          v-model="entryInput.data.comment"
          label="Kommentar"
          :readonly="
            entryInput.data.creator_id != userEmployeeId && entryInput.data.id
          "
        ></v-textarea>

        <v-card
          elevation="8"
          class="mb-4"
          v-if="entryInput.data.tasks && entryInput.data.tasks.length > 0"
        >
          <v-card-title class="bg-indigo">Aufgabe erstellt</v-card-title>
          <v-card-text class="mt-2">
            <v-chip
              class="mb-2"
              variant="elevated"
              :color="
                entryInput.data.tasks[0].is_completed ? 'success' : 'warning'
              "
            >
              <span v-if="entryInput.data.tasks[0].is_completed">Gelöst</span>
              <span v-else>Offen</span>
            </v-chip>
            <v-autocomplete
              chips
              multiple
              :items="users"
              v-model="entryInput.data.tasks[0].listeners"
              item-value="id"
              item-title="name"
              :readonly="
                entryInput.data.creator_id != userEmployeeId &&
                entryInput.data.id
              "
              label="Aufgabe zugewiesen an"
            ></v-autocomplete>
          </v-card-text>
        </v-card>
        <v-autocomplete
          v-if="false"
          chips
          multiple
          :items="users"
          v-model="entryInput.task_listeners"
          :readonly="
            entryInput.data.creator_id != userEmployeeId && entryInput.data.id
          "
          item-value="id"
          item-title="name"
          label="Aufgabe zuweisen an"
        ></v-autocomplete>
        <v-text-field
          type="date"
          readonly
          v-model="entryInput.data.date"
          label="Datum"
          :readonly="
            entryInput.data.creator_id != userEmployeeId && entryInput.data.id
          "
        ></v-text-field>
        <v-row>
          <v-col cols="12" align="center">
            <v-btn-toggle
              v-model="entryInput.data.status"
              mandatory
              :disabled="
                entryInput.data.creator_id != userEmployeeId &&
                entryInput.data.id
              "
            >
              <v-btn size="x-small" value="offen">Offen</v-btn>
              <v-btn size="x-small" value="gelöst">Gelöst</v-btn>
              <v-btn size="x-small" value="sonstiges">Sonstiges</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col align="left">
            <v-btn @click="entryInput.dialog = false" color="error">
              Abbrechen
            </v-btn>
          </v-col>
          <v-col align="right">
            <v-btn
              @click="
                saveEntry(entryInput.data, entryInput.task_listeners);
                entryInput.dialog = false;
              "
              :disabled="
                entryInput.data.creator_id != userEmployeeId &&
                entryInput.data.id
              "
              color="success"
            >
              Speichern
            </v-btn>
          </v-col>
        </v-row>
        <div v-if="entryInput.data.id">
          <div class="text-h5 my-4">Kommentare</div>
          <v-textarea
            v-model="commentMode.object.text"
            label="Schreiben Sie einen Kommentar"
            variant="solo"
            rows="3"
          ></v-textarea>
          <v-btn
            :disabled="!commentMode.object.text"
            class="mt-n4 mb-6"
            @click="addComment(commentMode, commentMode.object, task)"
            color="indigo"
            >Speichern</v-btn
          >
        </div>
        <v-row>
          <v-col
            cols="12"
            v-for="comment in entryInput.data.hotel_gold_on_duty_comments"
          >
            <div>
              <strong class="mr-2">{{ comment.employees.name }}</strong>
              {{
                new Date(comment.created_at).toLocaleDateString("de-DE", {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              }}
            </div>
            <v-card>
              <v-card-text>
                <template v-if="comment.edit">
                  <v-textarea
                    v-model="comment.text"
                    dense
                    outlined
                    rows="1"
                  ></v-textarea>
                  <v-btn
                    @click="
                      comment.edit = !comment.edit;
                      addComment(commentMode, comment);
                    "
                    color="indigo"
                    ><v-icon>mdi-content-save</v-icon></v-btn
                  >
                  <v-btn
                    class="ml-2"
                    @click="comment.edit = !comment.edit"
                    color="error"
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </template>
                <template v-else>
                  <v-row>
                    <v-col cols="10">
                      <div>{{ comment.text }}</div>
                    </v-col>
                    <v-col
                      cols="2"
                      align="right"
                      v-if="comment.owner_id == userEmployeeId"
                    >
                      <v-icon
                        @click="
                          comment.edit = !comment.edit;
                          commentMode.object = comment;
                        "
                        >mdi-pencil</v-icon
                      >
                    </v-col>
                  </v-row>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { supabase } from "@/supabase";
import { useStore } from "vuex";

const store = useStore();

const employeeList = ref([]);
const employeeObject = ref({});

const statusFilter = ref("all");
let todayShifts = ref([]);
let users = ref([]);
let hotels = ref([]);
let userEmployeeId = ref(null);
let entryInput = ref({
  dialog: false,
  data: {
    hotel_id: null,
    employee_id: null,
    problem: null,
    solution: null,
    status: "offen",
    date: new Date().toISOString().substr(0, 10),
  },
});

let pagination = ref({
  page: 1,
  length: 1,
  itemsPerPage: 10,
  count: 0,
});

let commentMode = ref({
  object: {},
  entry: {},
  fixedText: true,
});

let loading = ref(false);
let entries = ref([]);

let hotelFilter = ref(null);
let search = ref(null);

const checkFilter = (entry, index) => {
  const indexCheck =
    index >=
      pagination.value.page * pagination.value.itemsPerPage -
        pagination.value.itemsPerPage &&
    index <= pagination.value.page * pagination.value.itemsPerPage;
  return indexCheck;
};

const addComment = async (commentMode, comment) => {
  let payload = JSON.parse(JSON.stringify(comment));
  delete payload.edit;
  delete payload.public_users;
  if (!payload.gold_on_duty_entry_id) {
    payload.god_entry_id = commentMode.entry.id;
    commentMode.gold_on_duty_entry_id = commentMode.entry.id;
    payload.owner_id = userEmployeeId.value;
  }

  const { response, error } = await supabase
    .from("hotel_gold_on_duty_comments")
    .upsert(payload);
  updateComments(commentMode);
  comment.text = null;
};

const updateComments = async (commentMode) => {
  const response = await supabase
    .from("hotel_gold_on_duty_comments")
    .select("*,employees(first_name,last_name,name)")
    .eq("god_entry_id", commentMode.entry.id)
    .order("created_at", { ascending: false });
  entryInput.value.data.hotel_gold_on_duty_comments = response.data;
};

const getTodayShifts = async () => {
  let date = new Date();
  if (date.getHours() < 12) {
    date.setDate(date.getDate() - 1);
  }

  let start = date;
  start.setHours(2);
  let end = date;
  end.setHours(23);

  const response = await supabase
    .from("shifts")
    .select("*,hotels(name,reception_number)")
    .gte("date", start.toISOString())
    .lte("date", end.toISOString())
    .is("is_training", false)
    .gte("hotel_id", 0);

  response.data?.forEach((shift) => {
    shift.employee = employeeObject.value[shift.employee_id]
      ? employeeObject.value[shift.employee_id].name
      : null;
    shift.hotel = shift.hotels.name;
    shift.formattedDate = new Date(shift.date).toLocaleDateString();
    shift.label = `${shift.hotel} - ${shift.employee}`;
  });
  todayShifts.value = response.data;
};

const getEntries = async (newSearch) => {
  loading.value = true;
  if (newSearch) {
    pagination.value.page = 1;
  }
  const rangeStart =
    (pagination.value.page - 1) * pagination.value.itemsPerPage;
  const rangeEnd = pagination.value.page * pagination.value.itemsPerPage - 1;
  let query = supabase
    .from("hotel_gold_on_duty")
    .select(
      "*,employees:employee_id(first_name,last_name),creator:creator_id(first_name,last_name),hotel_gold_on_duty_comments(*,employees(first_name,last_name,name)),hotel:hotel_id(name),tasks(*)",
      { count: "exact" },
    )
    .range(rangeStart, rangeEnd);
  if (hotelFilter.value) {
    query = query.eq("hotel_id", hotelFilter.value);
  }
  if (search.value) {
    query = query.or(
      `problem.ilike.%${search.value}%,solution.ilike.%${search.value}%`,
    );
  }
  query.order("date", { ascending: false });
  const response = await query;
  response.data?.forEach((entry) => {
    entry.hotel_gold_on_duty_comments.sort(function (a, b) {
      return new Date(b.created_at) - new Date(a.created_at);
    });
    entry.employee = entry.employees
      ? entry.employees.first_name + " " + entry.employees.last_name
      : null;
    entry.creator = entry.creator
      ? entry.creator.first_name + " " + entry.creator.last_name
      : null;
    entry.json = JSON.stringify(entry);
    let date = new Date(entry.date);
    entry.hotel = entry.hotel ? entry.hotel : {};
    entry.formattedDate = new Date(date).toLocaleDateString("de-DE");
    entry.shortStatus = entry.status ? entry.status.split(" ")[0] : "sonstiges";
    if (entry.status && entry.status.split(" ")[1]) {
      entry.comment = entry.status;
    }
  });
  entries.value = response.data;
  if (statusFilter.value && statusFilter.value !== "all") {
    entries.value = entries.value.filter(
      (entry) => entry.shortStatus === statusFilter.value,
    );
  }
  loading.value = false;
  pagination.value.count = response.count;
  pagination.value.length = response.count / pagination.value.itemsPerPage;
};

const getHotels = async () => {
  const response = await supabase
    .from("hotels")
    .select("id,name")
    .order("name", { ascending: true });
  hotels.value = response.data;
};

const setShift = () => {
  entryInput.value.data.employee_id = entryInput.value.shift.employee_id;
  entryInput.value.data.hotel_id = entryInput.value.shift.hotel_id;
};

const saveEntry = async (entry, taskListeners) => {
  const payload = {
    creator_id: userEmployeeId.value,
    employee_id: entry.employee_id,
    hotel_id: entry.hotel_id,
    problem: entry.problem,
    solution: entry.solution,
    comment: entry.comment,
    status: entry.status,
    date: new Date(entry.date).toISOString(),
  };
  if (entry.id) {
    const response = await supabase
      .from("hotel_gold_on_duty")
      .update(payload)
      .eq("id", entry.id);
  } else {
    payload.creator_id = userEmployeeId.value;
    const response = await supabase
      .from("hotel_gold_on_duty")
      .insert(payload)
      .select("id");
    entry.id = response.data[0].id;
  }
  if (taskListeners && taskListeners.length > 0) {
    await createTask(entry, taskListeners);
  } else if (entry.tasks && entry.tasks.length > 0) {
    await updateTask(entry.tasks[0]);
  }
  getEntries();
};

const createTask = async (entry, taskListeners) => {
  let note = entry.solution;
  if (entry.comment) {
    note += "\n\nKommentar:\n" + entry.comment;
  }
  const payload = {
    title: entry.problem,
    due_date: new Date(entry.date).toISOString(),
    note: note,
    hotel_id: entry.hotel_id,
    employee_id: entry.employee_id,
    modified_at: new Date().toISOString(),
    owner_id: userId.value,
    todo_owner_id: userId.value,
    listeners: taskListeners,
    category_id: 13,
    god_entry_id: entry.id,
  };
  const response = await supabase.from("tasks").insert(payload);
};

const updateTask = async (task) => {
  const payload = {
    is_completed: task.is_completed,
    listeners: task.listeners,
    modified_at: new Date().toISOString(),
  };
  await supabase.from("tasks").update(payload).eq("id", task.id);
};

onMounted(async () => {
  const employeeResponse = await supabase
    .from("employees")
    .select("id,first_name,last_name")
    .eq("user_id", store.state.userInfo.id);
  userEmployeeId.value = employeeResponse.data[0].id;
  const response = await supabase
    .from("public_users")
    .select("id,first_name,last_name")
    .is("is_office", true)
    .is("active", true);
  response.data?.forEach((user) => {
    users.value.push({
      id: user.id,
      name: `${user.last_name}, ${user.first_name}`,
    });
  });
  getEntries();
  getTodayShifts();
  getHotels();
});
</script>

<style scoped>
.smallFont {
  size: 8px;
}
</style>
