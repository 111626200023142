<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-combobox
          v-model="employeeFilter"
          :items="employeeFilterOptions"
          label="Mitarbeiter"
          item-title="name"
          multiple
          chips
          clearable
        />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-combobox
          v-model="hotelFilter"
          :items="hotelFilterOptions"
          label="Hotel"
          item-title="name"
          item-value="id"
          multiple
          chips
          clearable
        />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-select
          v-model="monthFilter"
          :items="monthFilterOptions"
          label="Monat"
          clearable
        />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-checkbox
          v-model="showActiveFilter"
          label="Nur aktive Einarbeitungen anzeigen"
          hide-details
          density="compact"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="trainingDocs"
          :items-per-page="50"
          :headers="headers"
        >
          <template v-slot:item.employee="{ item }">
            <router-link :to="'/Mitarbeiter/' + item.employee.id">
              {{
                `${item?.employee?.last_name}, ${item?.employee?.first_name}`
              }}</router-link
            >
          </template>
          <template v-slot:item.hotel="{ item }">
            <v-chip>
              {{ item.hotel.short_name }}
              <v-tooltip activator="parent" location="right" open-delay="300">
                {{ item.hotel.name }}
              </v-tooltip>
            </v-chip>
          </template>
          <template v-slot:item.started_training="{ item }">
            {{ getFormattedShiftDate(item.started_training_at) }}
          </template>
          <template v-slot:item.trainingDocument="{ item }">
            <v-chip
              color="grey"
              label
              @click="openTrainingForm({ tableRow: item })"
            >
              {{ item.trainingDocument }}
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
  <v-dialog
    v-model="trainingFormsDialog.isOpen"
    transition="dialog-top-transition"
    fullscreen
  >
    <ProgressForm
      :trainee="trainingFormsDialog.employee"
      :hotel="trainingFormsDialog.hotel"
      :city="trainingFormsDialog.city"
      :is-senior="trainingFormsDialog.trainingResult.is_senior"
      :is-dialog="true"
      :close-dialog="
        () => {
          trainingFormsDialog.isOpen = false;
          fetchTrainingData();
        }
      "
    ></ProgressForm>
  </v-dialog>
</template>

<script setup>
import { onMounted, ref, watch, reactive } from "vue";
import { supabase } from "@/supabase";
import { parse, isSameMonth } from "date-fns";
import { de } from "date-fns/locale";
import ProgressForm from "../Trainings/ProgressForm.vue";
import getFormattedShiftDate from "@/utils/getFormattedShiftDate";

let trainingDocs = ref([]);
let headers = ref([
  {
    title: "Mitarbeiter",
    key: "employee",
    value: "employee",
    sort: (a, b) => a.last_name.localeCompare(b.last_name),
  },
  { title: "Stadt", key: "city", value: "city" },
  { title: "Hotel", key: "hotel", value: "hotel" },
  {
    title: "Begonnen am",
    key: "started_training_at",
    value: "started_training_at",
  },
  { title: "Dokument", key: "trainingDocument", value: "trainingDocument" },
]);

let trainingFormsDialog = reactive({
  employee: null,
  hotel: null,
  city: null,
  trainingResult: null,
  isOpen: false,
});

const employeeFilter = ref([]);
const hotelFilter = ref([]);
const monthFilter = ref(null);
const showActiveFilter = ref(true);

const monthFilterOptions = ref([]);
const hotelFilterOptions = ref([]);
const employeeFilterOptions = ref([]);

const prepareTableData = (dbTrainingResultData) => {
  const trainingData = [];

  dbTrainingResultData.forEach((trainingResult) => {
    trainingData.push({
      employee: trainingResult.employee,
      city: trainingResult.hotel.city.city,
      hotel: trainingResult.hotel,
      started_training_at: getFormattedShiftDate(
        trainingResult.started_training_at,
      ),
      trainingDocument: "Einarbeitungsliste",
      trainingResult,
    });
  });

  trainingDocs.value = trainingData;
};

// Helper function to check month using date-fns
const isWithinMonth = (dateString, month) => {
  const parsedMonth = parse(month, "LLLL yyyy", new Date(), { locale: de });
  const date = new Date(dateString);
  return isSameMonth(date, parsedMonth);
};

const fetchTrainingData = async () => {
  const filters = {
    employee: employeeFilter.value.map((e) => e.id),
    hotel: hotelFilter.value.map((h) => h.id),
    month: monthFilter.value,
    showActive: showActiveFilter.value,
  };

  let dataFetchQuery = supabase
    .from("training_hotel_results")
    .select(
      "*, employee:employees!training_hotel_result_trainee_id_fkey(id, last_name, first_name, name), hotel:hotels!inner(id, name, short_name, city:cities!inner(city))",
    );

  if (filters.employee.length > 0) {
    dataFetchQuery = dataFetchQuery.in("trainee_id", filters.employee);
  }

  if (filters.hotel.length > 0) {
    dataFetchQuery = dataFetchQuery.in("hotel.id", filters.hotel);
  }

  if (filters.showActive) {
    dataFetchQuery = dataFetchQuery
      .not("started_training_at", "is", null)
      .is("completed_training_at", null);
  } else {
    dataFetchQuery = dataFetchQuery.not("started_training_at", "is", null);
  }

  // Filter by time (Monthname yyyy)
  if (filters.month) {
    const parsedMonth = parse(filters.month, "LLLL yyyy", new Date(), {
      locale: de,
    });
    const monthStart = new Date(
      parsedMonth.getFullYear(),
      parsedMonth.getMonth(),
      1,
    );
    const monthEnd = new Date(
      parsedMonth.getFullYear(),
      parsedMonth.getMonth() + 1,
      1,
    );

    dataFetchQuery = dataFetchQuery.gte(
      "started_training_at",
      monthStart.toISOString(),
    );
    dataFetchQuery = dataFetchQuery.lte(
      "started_training_at",
      monthEnd.toISOString(),
    );
  }

  const { data, error } = await dataFetchQuery;

  if (error) {
    console.error(error);
    // TODO: handle error
    return;
  }

  prepareTableData(data);
};

/**
 * Function to fetch all filter options independently
 */
const fetchFilterOptions = async () => {
  // Fetch Employees
  const { data: employees, error: empError } = await supabase
    .from("employees")
    .select("id, last_name, first_name, name")
    .order("last_name", { ascending: true });

  if (empError) {
    // TODO: handle error
    console.error(empError);
    return;
  }
  employeeFilterOptions.value = employees;

  // Fetch Hotels
  const { data: hotels, error: hotelError } = await supabase
    .from("hotels")
    .select("id, name, short_name, city:cities(city)")
    .order("name", { ascending: true });

  if (hotelError) {
    console.error(hotelError);
    return;
  }
  hotelFilterOptions.value = hotels;

  // Fetch Months
  const { data: trainingResults, error: trError } = await supabase
    .from("training_hotel_results")
    .select("started_training_at");

  if (trError) {
    console.error(trError);
    return;
  }

  const months = new Set();
  trainingResults.forEach((tr) => {
    if (tr.started_training_at) {
      months.add(
        new Date(tr.started_training_at).toLocaleString("de", {
          month: "long",
          year: "numeric",
        }),
      );
    }
  });
  monthFilterOptions.value = Array.from(months).sort(
    (a, b) => new Date(b) - new Date(a),
  );
};

function openTrainingForm({ tableRow }) {
  trainingFormsDialog.employee = tableRow.employee;
  trainingFormsDialog.hotel = tableRow.hotel;
  trainingFormsDialog.city = tableRow.city;
  trainingFormsDialog.trainingResult = tableRow.trainingResult;
  trainingFormsDialog.isOpen = true;
}

onMounted(async () => {
  await fetchFilterOptions();
  await fetchTrainingData();
});

watch([employeeFilter, hotelFilter, monthFilter, showActiveFilter], () => {
  fetchTrainingData();
});
</script>
