// Utilities
import { createStore } from "vuex";
import { supabase } from "../supabase";
import axios from "axios";

import speedTest from "./speedTest"; // Import the speedTest module
import roles from "./roles"; // Import the roles module

export default createStore({
  modules: {
    speedTest, // Add the speedTest module to the store
    roles, // Add the roles module to the store
  },
  state: {
    days: [
      "Sonntag",
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag",
    ],
    applicationViews: {
      availability: false,
      reminder: false,
      others: true,
      tutorial: false,
      actions: [
        {
          label: "Erreichbarkeit und Reminder",
          icon: "mdi-phone-alert",
          view: "reminder",
          active: false,
        },
        {
          label: "Absage senden oder Status manuell anpassen",
          icon: "mdi-alert-box",
          view: "others",
          active: true,
        },
        {
          label: "Termin eintragen",
          icon: "mdi-calendar",
          dialog: "calendarDialog",
          active: false,
        },
        {
          label: "Was bedeutet der Status und was ist zu tun?",
          icon: "mdi-head-question",
          view: "tutorial",
          active: false,
        },
      ],
    },
    calendarDialog: false,
    appBarList: [
      {
        icon: "mdi-view-dashboard",
        title: "Dashboard",
        name: "Home",
        non_office: false,
        recruiting: true,
        color: "green",
      },
      {
        icon: "mdi-file-tree",
        title: "Aufgaben",
        name: "tasks",
        non_office: false,
        color: "grey-darken-3",
      },
      {
        icon: "mdi-account-search",
        title: "Recruiting",
        name: "recruiting",
        non_office: false,
        recruiting: true,
        color: "red",
      },
      {
        icon: "mdi-domain",
        title: "Hotels",
        name: "hotels",
        non_office: true,
        color: "indigo",
      },
      {
        icon: "mdi-account-group",
        title: "Mitarbeiter",
        name: "employees",
        non_office: false,
        color: "teal",
      },
      {
        icon: "mdi-timetable",
        title: "Schichten",
        name: "shifts",
        non_office: false,
        color: "teal",
      },
      {
        icon: "mdi-abacus",
        title: "Lohnbuchhaltung",
        name: "accounting",
        non_office: false,
        color: "blue",
      },
      {
        icon: "mdi-chart-bar",
        title: "Reporting",
        name: "reporting",
        non_office: false,
        color: "orange-darken-3",
      },
      {
        icon: "mdi-clipboard-account-outline",
        title: "Berichte",
        name: "reports",
        non_office: false,
        color: "blue-grey-darken-1",
      },
      {
        icon: "mdi-file-import",
        title: "Import",
        name: "import",
        non_office: false,
        color: "grey-darken-3",
      },
      {
        icon: "mdi-cog",
        title: "Settings",
        name: "settings",
        non_office: false,
        color: "blue-grey",
      },
    ],
    meetingStatuses: [
      { label: "Noch nicht angesetzt", value: null },
      { label: "Angesetzt", value: "invitationSend" },
      { label: "Angenommen", value: "invitationAccepted" },
      { label: "Abgelehnt", value: "invitationRefused" },
      { label: "Nicht erschienen", value: "notAttended" },
      { label: "Erschienen", value: "attended" },
      { label: "Keeunit eingeladen", value: "keeunitInvited" },
      { label: "Keeunit begonnen", value: "keeunitStarted" },
      { label: "Keeunit abgeschlossen", value: "keeunitFinished" },
      { label: "Test nicht bestanden", value: "testFailed" },
    ],
    appBarTaskUpdateTrigger: 0,
    meetingStatusObject: {
      null: { label: "Angesetzt", color: "white" },
      invitationSend: { label: "Angesetzt", color: "grey" },
      invitationAccepted: { label: "Akzeptiert", color: "green-lighten-2" },
      invitationRefused: { label: "Abgelehnt", color: "red-lighten-2" },
      notAttended: { label: "Nicht erschienen", color: "red-darken-2" },
      attended: { label: "Erschienen", color: "green-darken-2" },
      keeunitInvited: { label: "Keeunit eingeladen", color: "grey" },
      keeunitStarted: { label: "Keeunit begonnen", color: "green-lighten-2" },
      keeunitFinished: {
        label: "Keeunit abgeschlossen",
        color: "green-darken-2",
      },
      testFailed: {
        label: "Test nicht bestanden",
        color: "error",
      },
    },
    reminderTimes: {
      unit: "hours",
      count: 4,
      timeUnits: [
        { label: "Stunden", value: "hours" },
        { label: "Tage", value: "days" },
        { label: "Wochen", value: "weeks" },
      ],
    },
    googleCredentials: {
      access_token: null,
    },
    employeeFilter: {
      showHotels: true,
      hideBlockedEmployees: false,
      chosenKeys: [],
      selections: {
        employees: [],
      },
      hotels: [],
      employee_data_keys: [],
      role: null,
      users: [],
    },
    templatePlaceholders: [
      {
        label: "Sehr geehrter/geehrte Herr/Frau",
        placeholder: "{{polite_salutation}}",
        value: "polite_salutation",
        entity: null,
        for_employees: true,
      },
      {
        label: "Herr/Frau",
        placeholder: "{{gender}}",
        value: "gender",
        entity: null,
        for_employees: true,
      },
      {
        label: "Vorname",
        placeholder: "{{first_name}}",
        value: "first_name",
        entity: "recruiting_candidates",
      },
      {
        label: "Nachname",
        placeholder: "{{last_name}}",
        value: "last_name",
        entity: "recruiting_candidates",
      },
      {
        label: "Stellenbenzeichnung",
        placeholder: "{{position_name}}",
        value: "title",
        entity: "recruiting_jobs",
      },
      {
        label: "Nutzerrufnummer",
        placeholder: "{{user.phone_number}}",
        value: "phone",
        entity: "public_users",
        for_employees: true,
      },
      {
        label: "Nutzersignatur",
        placeholder: "{{user.signature}}",
        value: "signature",
        entity: "public_users",
        for_employees: true,
      },
      {
        label: "Nutzeremail",
        placeholder: "{{user.email}}",
        value: "email",
        entity: "public_users",
        for_employees: true,
      },
      {
        label: "Datum",
        placeholder: "{{date}}",
        value: "date",
        entity: null,
      },
      {
        label: "Uhrzeit",
        placeholder: "{{time}}",
        value: "time",
        entity: null,
      },
      {
        label: "Uhrzeit Start",
        placeholder: "{{timeStart}}",
        value: "timeStart",
        entity: null,
      },
      {
        label: "Uhrzeit Ende",
        placeholder: "{{timeEnd}}",
        value: "timeEnd",
        entity: null,
      },
      {
        label: "Mitarbeiter",
        placeholder: "{{employee_name}}",
        value: "name",
        entity: "employees",
      },
      {
        label: "Google Meet Link",
        placeholder: "{{googleMeetLink}}",
        value: "conferenceLink",
        entity: null,
      },
    ],
    emailView: {
      showNotMatchedEmails: true,
    },
    login: false,
    cvDataObject: {},
    username: {},
    userInfo: {},
    recruitingView: {
      tab: "jobs",
      jobEdit: {
        show: false,
        new: false,
        id: null,
      },
      candidateView: {
        drawer: false,
      },
    },
    applicationView: {
      filter: {
        jobs: [],
        status: [],
        rating: null,
        experienceLevels: [],
        timeModels: [],
        meetingStatuses: [],
        todo: true,
        chosenPhases: [1, 2, 3, 4],
      },
    },
    candidateChangeCheck: null,
    candidateView: {
      id: null,
    },
    emailDialog: false,
    hotelView: {
      email: true,
      search: null,
      drawer: false,
      employees: [],
      locations: [],
    },
    jobView: {
      cities: [],
      categories: [],
    },
    employeeView: {
      levels: [
        { color: "brown-lighten-1", name: "Bronze", value: true, level: 1 },
        { color: "grey-lighten-1", name: "Silber", value: true, level: 2 },
        { color: "yellow-darken-2", name: "Gold", value: true, level: 3 },
      ],
      drawer: false,
      dialog: false,
      newDialog: false,
    },
    typesOfWork: [
      "Festanstellung",
      "Geringfügige Beschäftigung",
      "Werkstudenten",
    ],
    typesOfRecruitingJobs: ["Nachtdienst", "Büro", "Sonstiges"],
    weekDays: [
      { value: 0, short_name: "So", long_name: "Sonntag", weekend: true },
      { value: 1, short_name: "Mo", long_name: "Montag" },
      { value: 2, short_name: "Di", long_name: "Dienstag" },
      { value: 3, short_name: "Mi", long_name: "Mittwoch" },
      { value: 4, short_name: "Do", long_name: "Donnerstag" },
      { value: 5, short_name: "Fr", long_name: "Freitag" },
      { value: 6, short_name: "Sa", long_name: "Samstag", weekend: true },
    ],
    cities: [
      { id: 1, city: "Hamburg", value: true },
      { id: 2, city: "Berlin", value: true },
      { id: 3, city: "Leipzig", value: true },
      { id: 4, city: "Bremen", value: true },
      { id: 5, city: "Rostock", value: true },
      { id: 7, city: "Dresden", value: true },
      { id: 10, city: "Stuttgart", value: true },
      { id: 11, city: "Lübeck", value: true },
    ],
  },
  actions: {
    formatDate(context, date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const hour = date.getHours();
      let minute = date.getMinutes();
      var showDate = `${day}.${month}.${year} ${hour}:`;
      if (minute.toString().length == 1) {
        minute = "0" + minute;
      }
      if (minute == "0" || minute == "5") {
        showDate = showDate + "0" + `${minute}`;
      } else {
        showDate = showDate + `${minute}`;
      }
      return showDate;
    },
    async getUserInfo(context) {
      if (!context.state.username || context.state.username == "null") {
        var info = await supabase.auth.getUser();
        context.state.username = info.data.user.email;
      }
      var userInfo = null; // localStorage.getItem("userInfo");
      if (userInfo && userInfo != "null") {
        context.state.userInfo = JSON.parse(userInfo);
      } else {
        var username = context.state.username;
        var response = await supabase
          .from("public_users")
          .select("*,public_user_rights(*)")
          .eq("email", username);
        response.data[0].userRights = response.data[0].public_user_rights[0];
        context.state.userInfo = response.data[0];
        var response = await context.dispatch(
          "getUserAvatar",
          context.state.userInfo.id,
        );
        if (response != "No Avatar") {
          context.state.userInfo.imageUrl = URL.createObjectURL(response.data);
        }
        localStorage.setItem(
          "userInfo",
          JSON.stringify(context.state.userInfo),
        );
      }
      return context.state.userInfo;
    },
    async getUserEmployeeId(context) {
      var userInfo = context.state.userInfo.id
        ? context.state.userInfo
        : await context.dispatch("getUserInfo");
      var queryObject = {
        table: "employees",
        select: "id",
        where: [{ type: "eq", key: "user_id", value: userInfo.id }],
      };
      var userEmployeeId = (
        await context.dispatch("getSupabaseEntity", queryObject)
      ).data[0].id;
      return userEmployeeId;
    },
    triggerAppBarTaskUpdate({ commit }) {
      commit("INCREMENT_TASK_UPDATE_TRIGGER");
    },
    async checkRole(context, queryObject) {
      if (!queryObject) {
        queryObject = {
          employeeId: null,
          userEmployeeId: null,
          chosenUserId: null,
        };
      }
      var employeeId = queryObject.employeeId;
      var userEmployeeId = queryObject.userEmployeeId;
      if (
        !context.state.userInfo ||
        (context.state.userInfo && !context.state.userInfo.email)
      ) {
        context.state.userInfo = await context.dispatch("getUserInfo");
      }
      var checkNightshift = false;
      if (employeeId != userEmployeeId) {
        checkNightshift = await context.dispatch("getSupabaseEntity", {
          select: "*",
          table: "employee_data_matching",
          where: [
            { type: "eq", key: "employee_id", value: employeeId },
            { type: "eq", key: "key_id", value: 6 },
          ],
        });
        checkNightshift =
          checkNightshift.data && checkNightshift.data[0]
            ? checkNightshift.data[0].value_id == 2
            : false;
      }

      if (queryObject.chosenUserId) {
        var checkRights = true;
        var filterUserId = queryObject.chosenUserId;
        var isNightshift = queryObject.is_nightshift;
      } else {
        var filterUserId = context.state.userInfo.id;
      }

      var queryObjectSections = {
        select:
          "*,public_user_role_section_matching(*,public_user_roles!inner(*,public_user_role_matching!inner(*)))",
        table: "employee_data_sections",
        where: [
          {
            type: "eq",
            key: "public_user_role_section_matching.public_user_roles.public_user_role_matching.user_id",
            value: filterUserId,
          },
        ],
      };
      var response = await context.dispatch(
        "getSupabaseEntity",
        queryObjectSections,
      );
      var sectionShow = {
        userId: filterUserId,
        showOwnEmployee: false,
        showAllEmployees: false,
        showEmployees: false,
        sections: [],
        sectionRights: {},
      };

      for (let i = 0; i < response.data.length; i++) {
        let section = response.data[i];
        let sectionObject = {
          sectionId: section.id,
          showAll: false,
          showOwn: false,
        };
        let roles = section.public_user_role_section_matching;
        sectionShow.sectionRights[section.id] = {
          showable: false,
          editable: false,
          suggestable: false,
        };
        for (let j = 0; j < roles.length; j++) {
          if (roles[j].show_nightshift_data) {
            sectionShow.showNightshiftEmployees = true;
            if (
              employeeId != userEmployeeId &&
              (isNightshift || !checkRights) &&
              checkNightshift
            ) {
              sectionShow.sectionRights[section.id].showable = true;
              sectionShow.showOwnEmployees = true;
              sectionObject.showOwn = true;
              if (roles[j].suggest_nightshift_data) {
                sectionShow.sectionRights[section.id].suggestable = true;
              }
              if (roles[j].edit_nightshift_data) {
                sectionShow.sectionRights[section.id].suggestable = true;
                sectionShow.sectionRights[section.id].editable = true;
              }
            }
          }
          if (roles[j].show_all_data) {
            sectionShow.showEmployees = true;
          }
          if (roles[j].show_all_data) {
            sectionShow.sectionRights[section.id].showable = true;
            sectionShow.showAllEmployees = true;
            sectionObject.showAll = true;
            if (roles[j].suggest_all_data) {
              sectionShow.sectionRights[section.id].suggestable = true;
            }
            if (roles[j].edit_all_data) {
              sectionShow.sectionRights[section.id].suggestable = true;
              sectionShow.sectionRights[section.id].editable = true;
            }
          } else if (roles[j].show_own_data && employeeId == userEmployeeId) {
            sectionShow.sectionRights[section.id].showable = true;
            sectionShow.showOwnEmployees = true;
            sectionObject.showOwn = true;
          }
          if (roles[j].edit_own_data && employeeId == userEmployeeId) {
            sectionShow.sectionRights[section.id].suggestable = true;
            sectionShow.sectionRights[section.id].editable = true;
          }
          if (roles[j].suggest_own_data && employeeId == userEmployeeId) {
            sectionShow.sectionRights[section.id].suggestable = true;
          }
        }
        if (sectionShow.sectionRights[section.id].showable) {
          sectionShow.sections.push(sectionObject.sectionId);
        }
      }

      var queryObjectDocumentCategories = {
        table: "employee_document_categories",
        select:
          "*,public_user_role_document_matching!inner(*,public_user_roles!inner(*,public_user_role_matching!inner(*)))",
        where: [
          {
            type: "eq",
            key: "public_user_role_document_matching.public_user_roles.public_user_role_matching.user_id",
            value: filterUserId,
          },
        ],
        order: { key: "name", ascending: true },
      };
      var fileRoleResponse = await context.dispatch(
        "getSupabaseEntity",
        queryObjectDocumentCategories,
      );
      sectionShow.documentCategories = fileRoleResponse.data;

      var queryObjectSegments = {
        table: "employee_segments",
        select:
          "*,public_user_role_segment_matching!inner(*,public_user_roles!inner(*,public_user_role_matching!inner(*)))",
        where: [
          {
            type: "eq",
            key: "public_user_role_segment_matching.public_user_roles.public_user_role_matching.user_id",
            value: filterUserId,
          },
        ],
      };
      var segmentRoleResponse = await context.dispatch(
        "getSupabaseEntity",
        queryObjectSegments,
      );
      sectionShow.segments = segmentRoleResponse.data;

      var queryObjectUserRoleMatching = {
        table: "public_user_role_matching",
        select: "*,public_user_roles(*)",
        where: [{ type: "eq", key: "user_id", value: filterUserId }],
      };
      var response = await context.dispatch(
        "getSupabaseEntity",
        queryObjectUserRoleMatching,
      );
      response.data.forEach((item) => {
        if (item.public_user_roles.can_end_contract) {
          sectionShow.can_end_contract = true;
        }
        if (item.public_user_roles.can_hide_employees) {
          sectionShow.can_hide_employees = true;
        }
        if (item.public_user_roles.can_change_user) {
          sectionShow.can_change_user = true;
        }
      });

      var queryObjectUserEmailMatching = {
        table: "public_user_role_email_group_matching",
        select:
          "*,email_groups(*),public_user_roles!inner(*,public_user_role_matching!inner(*))",
        where: [
          {
            type: "eq",
            key: "public_user_roles.public_user_role_matching.user_id",
            value: filterUserId,
          },
        ],
      };
      var response = await context.dispatch(
        "getSupabaseEntity",
        queryObjectUserEmailMatching,
      );
      sectionShow.emailGroups = response.data;
      return sectionShow;
    },
    async getAdminSupabaseData(context, queryObject) {
      var url = "/api/admin/users";
      var body = {
        authId: (await supabase.auth.getUser()).data.user.id,
      };
      var response = await axios.post(url, body);
      var users = response.data.users.data;
      return users;
    },
    async speedTest(context, sql) {
      if (sql) {
        var url = "/api/speed-test-sql";
      } else {
        var url = "/api/speed-test";
      }
      var response = await axios.get(url);
      return response;
    },
    async checkEmployeeStatus() {
      var url = "/api/admin/users/set-status";
      axios.post(url);
    },
    async createUserInSystem(context, queryObject) {
      var url = "/api/admin/users/create";
      queryObject.authId = (await supabase.auth.getUser()).data.user.id;
      var response = await axios.post(url, queryObject);
      if (response.error) {
        alert(response.error);
      } else {
        var publicUserId = response.data.publicUserId;
      }
      return publicUserId;
    },
    async changeEmailOfUser(context, queryObject) {
      var url = "/api/admin/users/update-email";
      var body = {
        authId: (await supabase.auth.getUser()).data.user.id,
        userObject: queryObject,
      };
      var response = await axios.post(url, body);
      return response;
    },
    async changePasswordOfUser(context, queryObject) {
      var url = "/api/admin/users/update-password";
      var body = {
        authId: (await supabase.auth.getUser()).data.user.id,
        userObject: queryObject,
      };
      var response = await axios.post(url, body);
      return response;
    },
    async updateUserRights(context, userRights) {
      var url = "/api/admin/users/update";
      var body = {
        authId: (await supabase.auth.getUser()).data.user.id,
        userRights: userRights,
      };
      var response = await axios.post(url, body);
      return response;
    },
    async arrayBufferToBase64(context, buffer) {
      return new Promise((resolve, reject) => {
        const blob = new Blob([buffer], { type: "application/octet-stream" });
        const reader = new FileReader();
        reader.onload = () => {
          const dataUrl = reader.result;
          const base64 = dataUrl.split(",")[1];
          resolve(base64);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },
    async sendEmailPerBackend(context, emailObject) {
      var url = "/api/google-apis/send-email";
      emailObject.user = "portal@bellcaptain.team";
      let apiKey = await context.dispatch("getSupabaseEntity", {
        table: "google_credentials",
        where: [{ type: "eq", key: "email", value: emailObject.user }],
      });
      apiKey = apiKey.data[0].email_api_key;

      // Convert files to base64
      if (emailObject.files && emailObject.files.length > 0) {
        emailObject.files = await Promise.all(
          emailObject.files.map(async (file) => {
            const arrayBuffer = await file.data.arrayBuffer();
            const base64Data = await context.dispatch(
              "arrayBufferToBase64",
              arrayBuffer,
            );
            return {
              ...file,
              data: base64Data,
            };
          }),
        );
      }
      emailObject.base64Files = true;
      var options = {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
        },
      };
      var response = await axios.post(url, emailObject, options);
      return response;
    },
    async getSupabaseEntity(context, queryObject) {
      if (!context.state.userInfo && !context.state.userInfo.id) {
        var response = await supabase
          .from("public_users")
          .select()
          .eq("email", auth.email);
        context.state.userInfo = response.data[0];
        localStorage.setItem(
          "userInfo",
          JSON.stringify(context.state.userInfo),
        );
        if (!context.state.userInfo && !context.state.userInfo.id) {
          alert(
            "Du bist nicht richtig eingeloggt. Bitte logge dich aus und nochmal ein!",
          );
        }
      }
      var table = queryObject.table;
      var select = queryObject.select;
      if (!select) {
        select = "*";
      }
      var query = supabase.from(table).select(select, { count: "exact" });
      if (queryObject.where) {
        queryObject.where.forEach(function (item) {
          if (item.type == "not") {
            query = query[item.type](item.key, "is", item.value);
          } else if (item.type == "or") {
            query = query[item.type](item.value);
          } else {
            query = query[item.type](item.key, item.value);
          }
        });
      }
      if (queryObject.limit) {
        query = query.limit(queryObject.limit);
      }
      if (queryObject.range) {
        query = query.range(
          queryObject.range.range_min,
          queryObject.range.range_max,
        );
      }
      if (queryObject.order) {
        query = query.order(queryObject.order.key, {
          ascending: queryObject.order.ascending,
        });
      }
      var { data: response, count: count, error } = await query;
      if (error) {
        console.log(error);
      }
      context.dispatch("checkAndPerformHourlySpeedTest");
      return { data: response, count: count };
    },
    async updateSupabaseEntity(context, queryObject) {
      var id = queryObject.id;
      var table = queryObject.table;
      var payload = queryObject.payload;
      var response = await supabase
        .from(table)
        .update(payload)
        .eq("id", id)
        .select();
      var error = response.error;
      if (error) {
        console.log(error);
        alert(error.message);
      }
      return response;
    },
    async upsertSupabaseEntity(context, queryObject) {
      var table = queryObject.table;
      var payload = queryObject.payload;
      var response = await supabase.from(table).upsert(payload).select();
      var error = response.error;
      if (error) {
        console.log(error);
        alert(error.message);
      }
      return response;
    },

    async createSupabaseEntity(context, queryObject) {
      var payload = queryObject.payload;
      var table = queryObject.table;
      delete payload.id;
      var response = await supabase.from(table).insert(payload).select();
      if (response.error) {
        alert(response.error.message);
      } else {
        if (queryObject.returnFullResponse) {
          return response;
        }
        var id = response.data[0].id;
        return id;
      }
    },
    async deleteSupabaseEntity(context, queryObject) {
      var table = queryObject.table;
      var id = queryObject.id;
      if (id) {
        var response = await supabase.from(table).delete().eq("id", id);
      }
      return response;
    },
    async getStorageFolderFiles(context, queryObject) {
      var bucket = queryObject.bucket;
      var folder = queryObject.folder;
      var response = await supabase.storage.from(bucket).list(folder);
      return response;
    },
    async retrieveStorageBucket(context, bucket) {
      var response = await supabase.storage.getBucket(bucket);
      return response;
    },
    async downloadStorageFile(context, queryObject) {
      var bucket = queryObject.bucket;
      var file = queryObject.file;
      var response = await supabase.storage.from(bucket).download(file);
      var fileUrl = URL.createObjectURL(response.data);
      return fileUrl;
    },
    async downloadStorageBlob(context, queryObject) {
      var bucket = queryObject.bucket;
      var file = queryObject.file;
      var response = await supabase.storage.from(bucket).download(file);
      return response;
    },
    async getStorageFileLink(context, queryObject) {
      var bucket = queryObject.bucket;
      var file = queryObject.file;
      file = file
        .replace("ä", "ae")
        .replace("ö", "oe")
        .replace("ü", "ue")
        .replace("ß", "ss");
      file = file.normalize("NFD");
      file = file.replace(/[\u0300-\u036f]/g, "");
      var fileUrl = await supabase.storage
        .from(bucket)
        .createSignedUrl(file, 60);
      return fileUrl.data.signedUrl;
    },
    async getPublicStorageFileLink(context, queryObject) {
      var bucket = queryObject.bucket;
      var file = queryObject.file;
      var fileUrl = await supabase.storage.from(bucket).getPublicUrl(file);
      return fileUrl.data.publicUrl;
    },
    async uploadStorageFile(context, queryObject) {
      var bucket = queryObject.bucket;
      var file = queryObject.file;
      var filePath = queryObject.filePath;
      filePath = filePath
        .replace("ä", "ae")
        .replace("ö", "oe")
        .replace("ü", "ue")
        .replace("ß", "ss");
      filePath = filePath.normalize("NFD");
      filePath = filePath.replace(/[\u0300-\u036f]/g, "");
      var response = await supabase.storage
        .from(bucket)
        .upload(filePath, file, { upsert: true });
      return response;
    },
    async renameStorageFile(context, queryObject) {
      var oldPath = queryObject.oldPath;
      var newPath = queryObject.newPath;
      var bucket = queryObject.bucket;
      var response = await supabase.storage.from(bucket).move(oldPath, newPath);
      return response;
    },
    async removeStorageFile(context, queryObject) {
      var bucket = queryObject.bucket;
      var file = queryObject.file;
      var response = await supabase.storage.from(bucket).remove([file]);
      return response;
    },
    async getUsers(context) {
      var response = await context.dispatch("getSupabaseEntity", {
        table: "public_users",
      });
      this.users = response.data;
      return this.users;
    },
    async getActiveUsers(context) {
      var response = await context.dispatch("getSupabaseEntity", {
        table: "public_users",
        where: [{ type: "is", key: "active", value: true }],
      });
      var users = response.data;
      return users;
    },
    async getUserAvatar(context, userId) {
      var response = "No Avatar";
      var files = await context.dispatch("getStorageFolderFiles", {
        bucket: "avatars",
        folder: userId,
      });
      if (files.data[0]) {
        var fileName =
          userId + "/avatar." + files.data[0].name.split(".").pop();
        response = await supabase.storage.from("avatars").download(fileName);
      }

      return response;
    },
    setGoogleResponse(context, data) {
      let dbId = 1;
      if (data.userId == "portal@bellcaptain.team") {
        dbId = 3;
      }
      var googlePayload = {
        table: "google_credentials",
        id: dbId,
        payload: {
          access_token: data.access_token,
          refresh_token: data.refresh_token,
          expiration_time_stamp: data.expirationTimestamp,
          last_change_date: new Date().toISOString(),
        },
      };
      if (data.userId) {
        context.dispatch("updateSupabaseEntity", googlePayload);
      }
    },
    async googleTokenCheck(context, userId) {
      userId = userId || "bewerbung@bellcaptain.team";
      var dateNow = Date.now();
      var response = await context.dispatch("getSupabaseEntity", {
        table: "google_credentials",
        where: [{ type: "eq", key: "email", value: userId }],
      });
      var googleCredentials = response.data[0];
      googleCredentials.userId = userId;
      var expirationTimestamp = googleCredentials.expiration_time_stamp;
      var checkToken = false;
      try {
        var checkToken = await axios.get(
          `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${googleCredentials.access_token}`,
        );
      } catch (error) {}

      if (!expirationTimestamp) {
        console.log("Not Logged In");
      } else if (expirationTimestamp < dateNow || checkToken.status != 200) {
        await context.dispatch("refreshToken", googleCredentials);
        var response = await context.dispatch("getSupabaseEntity", {
          table: "google_credentials",
          where: [{ type: "eq", key: "email", value: userId }],
        });
        var googleCredentials = response.data[0];
      }
      return googleCredentials;
    },
    async refreshToken(context, credentials) {
      var userId = credentials.userId;
      var refreshToken = credentials.refresh_token;
      const tokenEndpoint = "https://oauth2.googleapis.com/token";

      const data = {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
        client_id: credentials.client_id,
        client_secret: credentials.client_secret,
      };

      const response = await axios.post(tokenEndpoint, data);
      var googleCredentials = {};
      googleCredentials.access_token = response.data.access_token;
      googleCredentials.expires_in = response.data.expires_in;
      googleCredentials.userId = userId;
      googleCredentials.expirationTimestamp =
        Date.now() + response.data.expires_in * 1000;
      context.dispatch("setGoogleResponse", googleCredentials);
    },
    async replaceBody(context, object) {
      console.log(object);
      var userInfo = await context.dispatch("getUserInfo");
      var info = object.info;
      var body = object.body;
      var hotel = object.hotel;
      var conferenceLink = null;
      if (object.info.eventDetails && object.info.eventDetails.conferenceData) {
        var conferenceLink =
          "https://meet.google.com/" +
          object.info.eventDetails.conferenceData.conferenceId;
      }

      body = body.replace(/{{first_name}}/g, info.first_name);
      body = body.replace(/{{last_name}}/g, info.last_name);
      if (info.recruiting_job_candidates) {
        body = body.replace(
          /{{position_name}}/g,
          info.recruiting_job_candidates[0].recruiting_jobs.title,
        );
      }
      body = body.replace(/{{day}}/g, info.day);
      body = body.replace(/{{date}}/g, info.date);
      body = body.replace(/{{day2}}/g, info.day2);
      body = body.replace(/{{date2}}/g, info.date2);
      body = body.replace(/{{time}}/g, info.time);
      body = body.replace(/{{timeStart}}/g, info.timeStart);
      body = body.replace(/{{timeEnd}}/g, info.timeEnd);
      body = body.replace(/{{employee_name}}/g, info.employeeName);
      body = body.replace(/{{googleMeetLink}}/g, conferenceLink);
      body = body.replace(/{{user.phone_number}}/g, userInfo.phone);
      body = body.replace(/{{user.signature}}/g, userInfo.signature);

      if (hotel) {
        var hotelSoftware = hotel.software_id
          ? await context.dispatch("getIdValue", {
              selection_table: "hotel_software",
              selection_id: hotel.software_id,
            })
          : null;
        body = body.replace(/{{hotel_name}}/g, hotel.name);
        body = body.replace(/{{hotel_street}}/g, hotel.street);
        body = body.replace(/{{hotel_zip_code}}/g, hotel.zip_code);
        body = body.replace(/{{hotel_city}}/g, hotel.city);
        body = body.replace(/{{hotel_software_id}}/g, hotelSoftware);
        body = body.replace(
          /{{hotel_work_start}}/g,
          hotel.work_start ? hotel.work_start.slice(0, 5) : hotel.work_start,
        );
        body = body.replace(
          /{{hotel_work_end}}/g,
          hotel.work_start ? hotel.work_end.slice(0, 5) : hotel.work_end,
        );
        body = body.replace(/{{hotel_dress_code}}/g, hotel.dress_code);
      }

      if (object.matchingKeys) {
        for (const key of object.matchingKeys) {
          if (key.employee_data_matching[0]) {
            key.type = key.type == "selection" ? "text" : key.type;
            var value = key.employee_data_matching[0][`value_${key.type}`];
            var checkIdValue =
              key.type == "id" && body.indexOf(`{{${key.key_label}}}`) > -1;
            if (checkIdValue) {
              value = await context.dispatch("getIdValue", {
                selection_table: key.selection_table,
                selection_id: value,
              });
              console.log(value);
            }
            if (key.type == "date") {
              value = new Date(value).toLocaleDateString("de");
            }
            if (key.key_label == "gender") {
              info.gender = value == "Männlich" ? true : false;
            } else {
              let regex = new RegExp("{{" + key.key_label + "}}", "g");
              if (value) {
                body = body.replace(regex, value);
              } else {
              }
            }
          }
        }
      }
      if (info.gender) {
        body = body.replace(/{{gender}}/g, "Herr");
        body = body.replace(/{{polite_salutation}}/g, "Sehr geehrter Herr");
      } else if (info.gender == false) {
        body = body.replace(/{{gender}}/g, "Frau");
        body = body.replace(/{{polite_salutation}}/g, "Sehr geehrte Frau");
      }
      return body;
    },
    async getIdValue(context, queryObject) {
      var table = queryObject.selection_table;
      var id = queryObject.selection_id;
      var queryObject = {
        select: "*",
        table: table,
        where: [
          {
            type: "eq",
            key: "id",
            value: id,
          },
        ],
      };
      var response = await context.dispatch("getSupabaseEntity", queryObject);
      return response.data[0].name || response.data[0].city;
    },
    async sendGmail(context, emailObject) {
      var googleCredentials = await context.dispatch("googleTokenCheck");
      var htmlBody = JSON.parse(JSON.stringify(emailObject.body));
      htmlBody = htmlBody.replace(/<p>/g, "<div>").replace(/<\/p>/g, "</div>");
      var encodedSubject = encodeURIComponent(emailObject.subject)
        .replace(/%20/g, " ") // Replace spaces with '+', if needed
        .replace(/%26/g, "&") // Replace '&' with the correct encoding if needed
        .replace(/%2B/g, " "); // Replace '+' with the correct encoding if needed

      // Convert encoded subject to Uint8Array
      var uint8ArraySubject = new TextEncoder().encode(
        decodeURIComponent(encodedSubject),
      );

      // Convert Uint8Array to binary string
      var binaryString = String.fromCharCode(...uint8ArraySubject);

      // Base64 encode the binary string
      var base64EncodedSubject = btoa(binaryString);
      var boundary = "YOUR_CUSTOM_BOUNDARY_STRING";

      var infoArray = [
        "From: " + emailObject.from + "\r\n",
        "To: " + emailObject.to + "\r\n",
        "Cc: " + (emailObject.cc || "") + "\r\n",
        "Bcc: " + (emailObject.bcc || "") + "\r\n",
      ];

      if (emailObject.threadId) {
        infoArray.push("Subject:" + emailObject.oldSubject + "\r\n");
        infoArray.push(`References: ${emailObject.threadId}\r\n`);
        infoArray.push(`In-Reply-To: ${emailObject.threadId}\r\n`);
        htmlBody =
          htmlBody + `<br>---------------------<br><br>${emailObject.oldBody}`;
      } else {
        infoArray.push("Subject: =?UTF-8?B?" + base64EncodedSubject + "?=\r\n");
      }

      var contentArray = [
        "MIME-Version: 1.0\r\n",
        "Content-Type: multipart/mixed; boundary=" + boundary + "\r\n\r\n",
        "--" + boundary + "\r\n",
        "Content-Type: text/html; charset='UTF-8'\r\n",
        "Content-Transfer-Encoding: base64\r\n\r\n",
        "<html><body>" + htmlBody + "</body></html>\r\n",
      ];

      var messageArray = infoArray.concat(contentArray);

      if (emailObject.files && emailObject.files.length > 0) {
        for (var i = 0; i < emailObject.files.length; i++) {
          var file = emailObject.files[i];
          const arrayBuffer = await new Response(file.data).arrayBuffer();
          var uint8Array = new Uint8Array(arrayBuffer);
          // Chunk the array into smaller parts
          const chunkSize = 8192; // You can adjust the chunk size
          let chunks = [];
          for (let j = 0; j < uint8Array.length; j += chunkSize) {
            chunks.push(uint8Array.subarray(j, j + chunkSize));
          }

          // Convert each chunk to base64 and concatenate
          var base64Content = chunks
            .map((chunk) => btoa(String.fromCharCode.apply(null, chunk)))
            .join("");
          let binary = "";
          var bytes = new Uint8Array(arrayBuffer);
          var len = bytes.byteLength;
          for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          base64Content = btoa(binary);
          messageArray.push(
            "--" + boundary + "\r\n",
            "Content-Type: " + file.mimeType + "\r\n",
            "Content-Disposition: attachment; filename=" +
              file.fileName +
              "\r\n",
            "Content-Transfer-Encoding: base64\r\n\r\n",
            base64Content + "\r\n",
          );
        }
      }

      if (emailObject.cc) {
        messageArray.splice(3, 0, "CC: " + emailObject.cc + "\r\n");
      }
      if (emailObject.bcc) {
        messageArray.splice(3, 0, "BCC: " + emailObject.bcc + "\r\n");
      }

      messageArray.push("--" + boundary + "--");

      // Join the array elements into a string
      var messageString = messageArray.join("");
      var blob = new Blob([messageString], { type: "text/plain" });
      var reader = new FileReader();
      reader.onloadend = async function () {
        var base64EncodedMessage = reader.result.split(",")[1];

        // URL-safe replacements
        var safeBase64EncodedMessage = base64EncodedMessage
          .replace(/\+/g, "-")
          .replace(/\//g, "_");

        try {
          var payload = {
            raw: safeBase64EncodedMessage,
            threadId: emailObject.threadId,
          };
          const response = await axios.post(
            "https://www.googleapis.com/gmail/v1/users/me/messages/send",
            payload,
            {
              headers: {
                Authorization: `Bearer ${googleCredentials.access_token}`,
                "Content-Type": "application/json",
              },
            },
          );
        } catch (error) {
          console.log(error);
          console.error(
            "Error sending email:",
            error.response ? error.response.data : error.message,
          );
          alert(error.response);
        }
      };
      reader.readAsDataURL(blob);
      const listResponse = await axios.get(
        "https://www.googleapis.com/gmail/v1/users/me/messages",
        {
          headers: {
            Authorization: `Bearer ${googleCredentials.access_token}`,
          },
          params: {
            q: "in:sent", // Suche im "Gesendet"-Ordner
            maxResults: 1, // Nur die neueste Nachricht
          },
        },
      );
      const gmailResponse = {
        messageId: listResponse.data.messages[0].id,
        status: 204,
      };
      return gmailResponse;
    },
    async getEmailAttachments(context, { gmailId: gmailId, userId: userId }) {
      var attachments = [];
      var googleCredentials = await context.dispatch(
        "googleTokenCheck",
        userId,
      );
      var getEmailUrl = `https://gmail.googleapis.com/gmail/v1/users/${userId}/messages/${gmailId}`;
      var response = await axios.get(getEmailUrl, {
        headers: {
          Authorization: `Bearer ${googleCredentials.access_token}`,
        },
      });
      var messageData = response.data;
      if (messageData.payload && messageData.payload.parts) {
        // Loop through the parts to find attachments
        messageData.payload.parts.forEach((part) => {
          if (part.filename && part.body && part.body.attachmentId) {
            // Attachment found, add it to the attachments array
            attachments.push({
              filename: part.filename,
              mimeType: part.mimeType,
              attachmentId: part.body.attachmentId,
              size: part.body.size,
              downloadUrl: `https://www.googleapis.com/gmail/v1/users/me/messages/${messageData.id}/attachments/${part.body.attachmentId}`,
            });
          } else if (part.parts) {
            part.parts.forEach((subPart) => {
              if (
                subPart.filename &&
                subPart.body &&
                subPart.body.attachmentId
              ) {
                attachments.push({
                  filename: subPart.filename,
                  mimeType: subPart.mimeType,
                  attachmentId: subPart.body.attachmentId,
                  size: subPart.body.size,
                  downloadUrl: `https://www.googleapis.com/gmail/v1/users/me/messages/${messageData.id}/attachments/${subPart.body.attachmentId}`,
                });
              }
            });
          }
        });
      }
      return attachments;
    },
    async downloadAttachment(context, attachmentInfo) {
      var attachment = attachmentInfo.attachment;
      var userId = attachmentInfo.userId;
      var googleCredentials = await context.dispatch(
        "googleTokenCheck",
        userId,
      );
      var base64Response = await axios.get(attachment.downloadUrl, {
        headers: {
          Authorization: `Bearer ${googleCredentials.access_token}`,
        },
      });
      let base64Rep = base64Response.data.data
        .replace(/-/g, "+")
        .replace(/_/g, "/");

      let urlBlob = await context.dispatch("b64toBlob", {
        b64Data: base64Rep,
        contentType: attachment.mimeType,
        sliceSize: attachment.size,
      });

      var link = document.createElement("a");
      link.href = urlBlob;
      link.download = attachment.filename;

      link.click();
      window.URL.revokeObjectURL(urlBlob);
    },
    b64toBlob(context, object) {
      var b64Data = object.b64Data;
      var contentType = object.contentType;
      var sliceSize = object.sliceSize;
      contentType = contentType || "";
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      let urlBlob = URL.createObjectURL(blob);
      return urlBlob;
    },
    async checkCalendar(context, calendarObject) {
      var events = [];
      var entity = calendarObject.entity;
      var googleCredentials = await context.dispatch("googleTokenCheck");
      var today = new Date();
      today.setHours(2);
      today = today.toISOString();
      var start = today;
      var end = new Date();
      end.setDate(new Date().getDate() + 14);
      var end = end.toISOString();
      var calendarId = "bewerbung@bellcaptain.team";

      // Get the user's calendars
      var calendarListUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?`;
      if (entity) {
        calendarListUrl = calendarListUrl + "&q=" + entity.email;
      } else {
        calendarListUrl = calendarListUrl + `timeMin=${start}&timeMax=${end}`;
      }
      const response = await axios.get(calendarListUrl, {
        headers: {
          Authorization: `Bearer ${googleCredentials.access_token}`,
        },
      });
      for (var i = 0; i < response.data.items.length; i++) {
        var event = response.data.items[i];
        event.day = context.state.days[new Date(event.start.dateTime).getDay()];
        event.showDateStart = await context.dispatch(
          "formatDate",
          new Date(event.start.dateTime),
        );
        event.showDateEnd = await context.dispatch(
          "formatDate",
          new Date(event.end.dateTime),
        );
        var attendees = event.attendees;
        var searchArray = [];
        var checkAction = {};
        if (attendees) {
          attendees.forEach(function (item) {
            if (item.email != "bewerbung@bellcaptain.team") {
              searchArray.push(item.email);
            }
            checkAction[item.email] = item.responseStatus;
          });
          var queryObject = {
            select: "id,first_name,last_name,email",
            table: "recruiting_candidates",
            where: [{ type: "in", key: "email", value: searchArray }],
          };
          var dbResponse = await context.dispatch(
            "getSupabaseEntity",
            queryObject,
          );
          attendees = dbResponse.data;
          attendees.forEach(function (item, index, arr) {
            arr[index].responseStatus = checkAction[item.email];
          });

          event.attendees = dbResponse.data;
        }
        if (!event.recurrence) {
          events.push(event);
        }
      }
      return events;
    },
    async updateEvent(context, event) {
      var googleCredentials = await context.dispatch("googleTokenCheck");
      var calendarId = "bewerbung@bellcaptain.team";
      var apiEvent = {
        summary: event.summary,
        description: event.description,
        start: {
          dateTime: await context.dispatch("toISOLocal", new Date(event.start)),
          timeZone: "Europe/Berlin",
        },
        end: {
          dateTime: await context.dispatch("toISOLocal", new Date(event.end)),
          timeZone: "Europe/Berlin",
        },
        attendees: event.attendees,
        guestsCanSeeOtherGuests: false,
        guestsCanInviteOthers: false,
        conferenceData: {
          createRequest: {
            requestId: "jobInterviewTest",
            conferenceSolutionKey: {
              type: "hangoutsMeet",
            },
          },
        },
      };
      if (event.id) {
        var calendarUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${event.id}`;
        var eventResponse = await axios.get(calendarUrl, {
          headers: {
            Authorization: `Bearer ${googleCredentials.access_token}`,
          },
        });
        var existingEvent = eventResponse.data;
        existingEvent.start = apiEvent.start;
        existingEvent.end = apiEvent.end;
        existingEvent.attendees = apiEvent.attendees;
        var response = await axios.put(calendarUrl, existingEvent, {
          headers: {
            Authorization: `Bearer ${googleCredentials.access_token}`,
          },
        });
      } else {
        var calendarUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`;
        if (event.online) {
          calendarUrl = calendarUrl + "?conferenceDataVersion=1";
        }
        var response = await axios.post(calendarUrl, apiEvent, {
          headers: {
            Authorization: `Bearer ${googleCredentials.access_token}`,
          },
        });
        /*if (event.owner) {
          var eventId = response.data.id
          var calendarUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}/move`
          var changeOwnerObject = {
            destination:event.owner
          }
          await axios.post(calendarUrl,changeOwnerObject, {
            headers: {
              Authorization: `Bearer ${googleCredentials.access_token}`,
            }
          })
        }*/
      }
      return response;
    },
    async deleteEvent(context, eventId) {
      var googleCredentials = await context.dispatch("googleTokenCheck");
      var calendarId = "bewerbung@bellcaptain.team";
      var calendarUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`;
      var response = await axios.delete(calendarUrl, {
        headers: {
          Authorization: `Bearer ${googleCredentials.access_token}`,
        },
      });
    },
    toISOLocal(context, d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off > 0 ? "-" : "+";
      off = Math.abs(off);

      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        sign +
        z((off / 60) | 0) +
        ":" +
        z(off % 60)
      );
    },
    async sleep(context, time) {
      if (!time) {
        time = 2;
      }
      return new Promise((resolve) => setTimeout(resolve, time));
    },
  },
  mutations: {
    INCREMENT_TASK_UPDATE_TRIGGER(state) {
      state.appBarTaskUpdateTrigger++;
    },

    initializeStore(state) {
      state.username = localStorage.getItem("username");
      state.login = localStorage.getItem("login");
      if (localStorage.getItem("chosenStatusPhases")) {
        state.applicationView.filter.chosenPhases = JSON.parse(
          localStorage.getItem("chosenStatusPhases"),
        );
      }
      if (localStorage.getItem("applicationStatusFilter")) {
        state.applicationView.filter.status = JSON.parse(
          localStorage.getItem("applicationStatusFilter"),
        );
      }
      state.recruitingView.tab = localStorage.getItem("recruitingTab");
      if (
        localStorage.getItem("googleCredentials") &&
        localStorage.getItem("googleCredentials") != "undefined"
      ) {
        state.googleCredentials = JSON.parse(
          localStorage.getItem("googleCredentials"),
        );
      }
    },
  },
});
//
