<template>
  <v-card class="d-flex flex-column" style="height: 100vh">
    <v-card-title
      class="bg-blue-grey-darken-3 d-flex align-center px-3 fixed-top"
    >
      <v-img src="../../assets/bellcaptain_glocke.png" height="56px"></v-img>
      <span :style="`font-size: ${smAndUp ? '20px' : '15px'};`">
        Einarbeitunsabnahme
      </span>
      <v-spacer></v-spacer>
      <v-icon
        v-if="!isTestComplete"
        @click="openDeletionDialog"
        class="cursor-pointer mx-4"
        color="red"
      >
        mdi-delete
      </v-icon>
      <v-icon @click="closeDialog()" class="cursor-pointer">mdi-close</v-icon>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-stepper alt-labels height="100%" class="view-content">
        <template v-slot:default="{ prev, next }">
          <v-stepper-header class="fixed-stepper">
            <v-stepper-item
              title="Hinweise"
              value="1"
              :complete="confirmationsChecked"
              editable
            >
            </v-stepper-item>

            <v-divider></v-divider>

            <v-stepper-item
              title="Aufgaben"
              value="2"
              :editable="confirmationsChecked"
            ></v-stepper-item>

            <v-divider></v-divider>

            <v-stepper-item
              title="Ergebnis"
              value="3"
              :editable="confirmationsChecked"
            ></v-stepper-item>
          </v-stepper-header>
          <v-stepper-window>
            <v-stepper-window-item value="1">
              <v-container fluid class="px-1">
                <v-row no-gutters>
                  <v-col cols="12" md="8" offset="md-2">
                    <v-card elevation="3" class="ma-1">
                      <v-card-text>
                        <v-row no-gutters>
                          <v-col cols="2" xs="2" md="1" align="center">
                            <v-icon>mdi-map-marker</v-icon>
                          </v-col>
                          <v-col cols="9" xs="9" md="11">{{ city }}</v-col>
                          <v-col cols="2" xs="2" md="1" align="center">
                            <v-icon>mdi-domain</v-icon>
                          </v-col>
                          <v-col cols="9" xs="9" md="11">
                            {{ hotel.name }}
                          </v-col>
                          <v-col cols="2" xs="2" md="1" align="center">
                            <v-icon>mdi-calendar</v-icon>
                          </v-col>
                          <v-col cols="9" xs="9" md="11">
                            {{ signatureDate }}
                          </v-col>
                          <v-col cols="2" xs="2" md="1" align="center">
                            <v-icon>mdi-account</v-icon>
                          </v-col>
                          <v-col cols="9" xs="9" md="11">
                            {{ trainee?.name }}
                          </v-col>
                          <v-col cols="2" xs="2" md="1" align="center"
                            >Level:</v-col
                          >
                          <v-col cols="9" xs="9" md="11">
                            {{
                              trainingHotelResults.is_senior
                                ? "Senior"
                                : "Clerk"
                            }}
                          </v-col>
                          <v-col
                            cols="12"
                            class="mt-2 d-flex justify-end align-center"
                          >
                            Abnahme durch
                            {{ examiner?.name ?? "" }}
                            <v-skeleton-loader
                              v-if="!examiner?.name"
                              width="100px"
                              type="text"
                            >
                            </v-skeleton-loader>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-row class="mt-2 px-1">
                      <v-col cols="12"><h4>Prüfungshinweise</h4></v-col>
                      <v-col cols="12">
                        <p>
                          Zur Sicherstellung einer vollständigen Einarbeitung
                          und zur Wahrung der Bellcaptain-Standards muss zur
                          Beendigung dieser eine Einarbeitungsabnahme erfolgen.
                        </p>
                        <br />
                        <p>
                          Im Folgenden werden aus den Bereichen
                          Notfalleinrichtungen, Hotelübersicht, Hotelumgebung,
                          Allgemeines, Bellcaptain sowie Hotelsystem Fragen
                          gestellt. Diese können
                          <strong>ohne Hilfestellung (1 Punkt)</strong> oder
                          <strong
                            >mit Hilfe der Dienstanweisung (0,5 Punkte)</strong
                          >
                          beantwortet werden, andernfalls werden
                          <strong>0 Punkte</strong> vergeben.
                        </p>
                        <br />
                        <p>
                          Außerdem werden Fragen zu den zu erbringenden
                          hotelspezifischen Dienstleistungen gestellt. Diese
                          können
                          <strong>ohne Hilfestellung (3 Punkte)</strong>,
                          <strong
                            >mit Hilfe der Dienstanweisung (2 Punkte)</strong
                          >
                          oder
                          <strong
                            >mit zusätzlicher Hilfe des Prüfers (1
                            Punkt)</strong
                          >
                          beantwortet werden, andernfalls werden
                          <strong>0 Punkte</strong> vergeben.
                        </p>
                        <br />
                        <p>
                          Zudem gibt es Fragen über sicherheitsrelevante
                          Aufgaben / Informationen und grundlegende
                          Systemfragen, die zwingend ohne Hilfestellung oder
                          mindestens mit Hilfe der Dienstanweisung vollständig
                          und korrekt beantwortet werden müssen, um die
                          Einarbeitungsabnahme zu bestehen. Diese Aufgaben sind
                          mit ⚠ gekennzeichnet.
                        </p>
                        <br />
                        <p>
                          Um die Einarbeitungsabnahme vollständig zu bestehen,
                          sind mindestens
                          <strong>90%</strong> notwendig.
                        </p>
                        <p>
                          Bei <strong>75 - 89%</strong> ist keine weitere
                          Einarbeitung mehr notwendig, jedoch wird der
                          Mitarbeiter in den ersten Nächten weiter betreut und
                          ggf. entsprechend nachgeschult.
                        </p>
                        <p>
                          Bei <strong>50 - 74%</strong> wird eine Weiterführung
                          der Einarbeitung empfohlen, da der Mitarbeiter noch zu
                          unsicher ist oder größere Fehler bzw. Wissenslücken
                          aufgetreten sind.
                        </p>
                        <p>
                          Alles <strong>unter 50%</strong> oder das
                          <strong>
                            nicht oder falsch Beantworten einer
                            sicherheitsrelevanten / grundlegenden Systemaufgabe
                            / Information
                          </strong>
                          führt zum Nichtbestehen und somit zur Weiterführung
                          der Einarbeitung.
                        </p>
                        <br />
                        <v-checkbox
                          :readonly="isTestStarted"
                          v-model="traineeConfirmation"
                          label="Hiermit bestätigt der Mitarbeiter vor Beginn der Einarbeitungsabnahme die Prüfungshinweise gelesen und vollständig verstanden zu haben."
                        ></v-checkbox>
                        <v-checkbox
                          :readonly="isTestStarted"
                          v-model="examinerConfirmation"
                          label="Hiermit bestätigt der Prüfer, dass er dem Mitarbeiter vor Beginn der Einarbeitungsabnahme die Prüfungshinweise vorgelegt hat und diesen vollständig darüber aufgeklärt hat."
                        ></v-checkbox>
                      </v-col>
                      <v-col align="center">
                        <v-btn
                          :disabled="!confirmationsChecked"
                          color="green"
                          class="mt-3"
                          @click="next"
                          >Weiter</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-window-item>

            <v-stepper-window-item value="2">
              <v-container fluid class="px-1">
                <v-row no-gutters>
                  <v-col cols="12" md="8" offset="md-2">
                    <FinalTaskEvaluationForm
                      :taskExaminationForm="taskExaminationForm"
                      :isCompleted="isTestComplete"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-window-item>

            <v-stepper-window-item value="3">
              <v-container fluid class="px-1">
                <v-row no-gutters>
                  <v-col cols="12" md="8" offset="md-2">
                    <v-row>
                      <v-col cols="4" sm="4" md="2" offset="md-4">
                        <div class="battery-icon-wrapper">
                          <div class="battery-bar">
                            <v-progress-linear
                              :model-value="totalPercentage"
                              height="30"
                              :color="resultsColor"
                            >
                              <template v-slot:default="{ value }">
                                <strong
                                  >{{ Math.floor(value) }}% ({{
                                    totalPoints
                                  }}
                                  Punkte)</strong
                                >
                              </template>
                            </v-progress-linear>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="8" sm="8" md="6">
                        <v-row
                          v-for="(range, index) in progressMarkers"
                          class="point-range-element"
                          no-gutters
                        >
                          <v-col cols="auto" class="d-flex align-center mr-2">
                            <v-tooltip
                              location="bottom"
                              open-on-click
                              max-width="300"
                            >
                              <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" size="xs-small">
                                  mdi-information-outline
                                </v-icon>
                              </template>
                              <span v-html="range.assessmentText"></span>
                            </v-tooltip>
                          </v-col>
                          <v-col cols="auto" class="d-flex align-center">
                            <span
                              class="mr-2"
                              :style="{
                                textDecoration: range.isResult
                                  ? 'underline'
                                  : 'none',
                              }"
                            >
                              <strong
                                v-if="index < progressMarkers.length - 1"
                                >{{
                                  `${range.minPoints} - ${range.maxPoints} Punkte`
                                }}</strong
                              >
                              <v-icon v-else>mdi-alert-outline</v-icon>
                            </span>
                            <v-icon
                              size="large"
                              v-if="range.isResult"
                              :color="resultsColor"
                            >
                              mdi-arrow-left-bold
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-divider></v-divider>
                      </v-col>
                      <v-col cols="12">
                        <span
                          v-html="
                            progressMarkers.find((marker) => marker.isResult)
                              .assessmentText
                          "
                        ></span>
                      </v-col>
                      <v-col cols="12">
                        <v-divider></v-divider>
                      </v-col>
                      <v-col cols="12" align-self="center">
                        <v-checkbox
                          v-model="feedbackComment.visible_to_others"
                          label="Bemerkung freigeben"
                          hide-details
                          density="compact"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12">
                        <v-expansion-panels
                          static
                          :model-value="commentFieldPanel"
                        >
                          <v-expansion-panel title="Bemerkung">
                            <v-expansion-panel-text>
                              <v-container fluid>
                                <v-row no-gutters>
                                  <v-col cols="12">
                                    <v-textarea
                                      :readonly="isTestComplete"
                                      v-model="feedbackComment.comment"
                                    ></v-textarea>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-expansion-panel-text>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                      <!-- Signature fields -->
                      <v-col cols="12" xs="12" sm="6">
                        <v-card class="px-0" elevation="3" rounded="lg">
                          <v-card-title>
                            <v-row>
                              <v-col cols="10">
                                <span style="font-size: 14px">
                                  Unterschrift Mitarbeiter,
                                  {{ signatureDate }}
                                </span>
                              </v-col>
                            </v-row>
                          </v-card-title>
                          <v-card-text class="pa-0">
                            <v-img
                              :src="
                                trainingHotelResults.final_signature_trainee
                              "
                              height="100px"
                            />
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="12" xs="12" sm="6">
                        <v-card class="px-0" elevation="3" rounded="lg">
                          <v-card-title>
                            <v-row>
                              <v-col cols="10">
                                <span style="font-size: 14px">
                                  Unterschrift Prüfer,
                                  {{ signatureDate }}
                                </span>
                              </v-col>
                            </v-row>
                          </v-card-title>
                          <v-card-text class="pa-0">
                            <v-img
                              :src="
                                trainingHotelResults.final_signature_examiner
                              "
                              height="100px"
                            />
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col
                        v-if="isResultReviewed"
                        cols="12"
                        class="d-flex justify-center"
                      >
                        <v-icon color="green" class="mr-1">
                          mdi-check-circle
                        </v-icon>
                        Ergebnis genehmigt am
                        {{
                          new Date(
                            trainingHotelResults?.reviewed_final_at,
                          ).toLocaleDateString("de")
                        }}
                        <span v-if="reviewer?.name">
                          &nbsp;durch {{ reviewer?.name }}
                        </span>
                      </v-col>
                      <v-col cols="12" class="d-flex justify-center">
                        <div>
                          <v-btn
                            @click="reviewResult"
                            color="success"
                            :loading="isSubmitting"
                            :disabled="isSubmitting || !isTestComplete"
                          >
                            {{ isResultReviewed ? "Speichern" : "Genehmigen" }}
                          </v-btn>
                        </div>
                        <div
                          v-if="
                            progressMarkers?.find((marker) => marker.isResult)
                              ?.gradingId <= 3
                          "
                        >
                          <v-checkbox-btn
                            label="Einarbeitung abgeschlossen"
                            hide-details
                            :disabled="isTrainingComplete"
                            :model-value="isTrainingComplete"
                            @click="setCompletedAtTrainingDate"
                          />
                        </div>
                        <div
                          v-if="
                            progressMarkers?.find((marker) => marker.isResult)
                              ?.gradingId > 3
                          "
                        >
                          <v-checkbox-btn
                            label="Einarbeitung noch nicht abgeschlossen"
                            hide-details
                            indeterminate
                            disabled
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-window-item>
          </v-stepper-window>
        </template>
      </v-stepper>
    </v-card-text>
  </v-card>
  <v-snackbar v-model="snackbar.isOpen" timeout="3000" :color="snackbar.color">
    {{ snackbar.text }}
  </v-snackbar>
  <v-dialog v-model="deletionDialog.isOpen" max-width="500">
    <v-card>
      <v-card-title class="headline bg-red-darken-2">
        Löschung bestätigen
      </v-card-title>
      <v-card-text>
        <div>
          Die angefangene finale Abnahme wird gelöscht.
          <br />
          <br />
          Zusätzlich wird gelöscht:
          <br />
          <strong>{{ deletionDialog.finalTaskResults.length }}</strong>
          Aufgaben-Bewertung{{
            deletionDialog.finalTaskResults.length === 1 ? "" : "en"
          }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" text @click="deletionDialog.isOpen = false">
          Abbrechen
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="green" text @click="deleteTrainingResults">
          Bestätigen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import FinalTaskEvaluationForm from "./FinalTaskEvaluationForm.vue";
import { ref, computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { useDisplay } from "vuetify";
import { supabase } from "@/supabase";
import { ASSESSMENT_RANGE_TEXTS } from "./constants.js";
import getFormattedShiftDate from "@/utils/getFormattedShiftDate";

const store = useStore();
const { smAndUp } = useDisplay();

const props = defineProps({
  trainee: Object,
  hotel: Object,
  city: String,
  trainingResult: Object,
  closeDialog: Function,
});

const examiner = ref(null);
const trainee = ref(props.trainee);
const hotel = ref(props.hotel);
const city = ref(props.city);
const closeDialog = props.closeDialog;
const trainingHotelResults = ref(props.trainingResult);
const choiceOptions = ref([]);
const examinationDate = ref(getFormattedShiftDate(new Date().toISOString()));
const isTrainingComplete = ref(false);

const currentUser = ref(null);
const taskExaminationForm = ref([]);
const commentFieldPanel = ref([0]);
const feedbackComment = ref({});
const reviewer = ref(null);

const signatureDate = ref(new Date().toLocaleDateString("de"));

const isTestStarted = computed(() =>
  Boolean(trainingHotelResults?.value?.started_final_at),
);

const isTestComplete = computed(() =>
  Boolean(trainingHotelResults?.value?.completed_final_at),
);

const isResultReviewed = computed(() =>
  Boolean(trainingHotelResults?.value?.reviewed_final_at),
);

const deletionDialog = reactive({
  isOpen: false,
  finalTaskResults: [],
});

const openDeletionDialog = async () => {
  try {
    const { data, error } = await supabase
      .from("training_hotel_task_results")
      .select("id, intermediary_training_choice_id, final_training_choice_id")
      .eq("training_hotel_result_id", trainingHotelResults.value.id)
      .not("final_training_choice_id", "is", null);

    deletionDialog.finalTaskResults = data ?? [];
    deletionDialog.isOpen = true;
  } catch (error) {
    console.error("error opening deletion dialog: ", error);
    snackbar.text = "Fehler beim Laden der Daten für den Löschvorgang.";
    snackbar.color = "error";
    snackbar.isOpen = true;
  }
};

const deleteTrainingResults = async () => {
  try {
    deletionDialog.finalTaskResults.forEach(async (taskResult) => {
      if (taskResult.intermediary_training_choice_id) {
        const { error } = await supabase
          .from("training_hotel_task_results")
          .update({
            final_training_choice_id: null,
          })
          .eq("id", taskResult.id);
      } else {
        const { error } = await supabase
          .from("training_hotel_task_results")
          .delete()
          .eq("id", taskResult.id);

        if (error) {
          throw error;
        }
      }
    });

    if (
      !trainingHotelResults.value.started_training_at &&
      !trainingHotelResults.value.started_intermediary_at
    ) {
      const { error } = await supabase
        .from("training_hotel_results")
        .delete()
        .eq("id", trainingHotelResults.value.id);

      if (error) {
        throw error;
      }
    } else {
      const { error } = await supabase
        .from("training_hotel_results")
        .update({
          started_final_at: null,
          final_examiner_id: null,
        })
        .eq("id", trainingHotelResults.value.id);

      if (error) {
        throw error;
      }
    }
    snackbar.text = "Finale Abnahme erfolgreich gelöscht.";
    snackbar.color = "success";
    snackbar.isOpen = true;
    deletionDialog.isOpen = false;
    await new Promise((resolve) => setTimeout(resolve, 2000));
    closeDialog();
  } catch (error) {
    console.error("error deleting training results: ", error);
    snackbar.text = "Fehler beim Löschen der finalen Abnahme.";
    snackbar.color = "error";
    snackbar.isOpen = true;
  }
};

const reviewResult = async () => {
  try {
    snackbar.text = !isResultReviewed.value
      ? "Ergebnis genehmigt."
      : "Änderungen gespeichert.";

    isSubmitting.value = true;
    if (!isResultReviewed.value) {
      const { data, error } = await supabase
        .from("training_hotel_results")
        .update({
          reviewed_final_at: new Date(),
          final_reviewer_id: currentUser.value.id,
        })
        .eq("id", trainingHotelResults.value.id)
        .select();

      if (error) {
        throw error;
      }

      trainingHotelResults.value = data[0];
      reviewer.value = currentUser.value;
    }

    if (feedbackComment.value.id) {
      await reviewFeedbackComment();
    }

    snackbar.color = "success";
    snackbar.isOpen = true;
  } catch (error) {
    console.error("Error reviewing result: ", error);
    snackbar.text = "Fehler beim Speichern des Ergebnisses.";
    snackbar.color = "error";
    snackbar.isOpen = true;
  } finally {
    isSubmitting.value = false;
  }
};

const isSubmitting = ref(false);

const snackbar = reactive({
  isOpen: false,
  text: "",
  color: "",
});

// Confirmations for having read the instructions
const traineeConfirmation = ref(false);
const examinerConfirmation = ref(false);
const confirmationsChecked = computed(
  () => traineeConfirmation.value && examinerConfirmation.value,
);

const initializeTaskExaminationForm = (formStructure) => {
  const filteredFormStructure = formStructure.filter(
    (category) =>
      (category.is_general && category.training_general_tasks.length > 0) ||
      (!category.is_general && category.hotel_specific_tasks.length > 0),
  );

  filteredFormStructure
    .map((category) => {
      category.training_general_tasks.sort((a, b) => a.order - b.order);
      category.hotel_specific_tasks.sort((a, b) => a.order - b.order);
    })
    .sort((a, b) => a.order - b.order);
  taskExaminationForm.value = filteredFormStructure;
};

const resultsColor = computed(() => {
  const currentResult = progressMarkers.value.find((marker) => marker.isResult);
  switch (currentResult?.gradingId) {
    case 1:
    case 2:
      return "success";
    case 3:
      return "yellow";
    case 4:
    case 5:
      return "error";
    default:
      return "grey";
  }
});

/** ------- Handle Training Completion ------- */

const fetchIsTrainingComplete = async () => {
  if (
    trainingHotelResults.value.completed_training_at ||
    trainingHotelResults.value.training_result_grading_id < 3
  ) {
    isTrainingComplete.value = true;
  }

  if (trainingHotelResults.value.training_result_grading_id === 3) {
    const { data, error } = await supabase
      .from("training_hotel_results")
      .select("id")
      .eq("trainee_id", trainee.value.id)
      .eq("hotel_id", hotel.value.id)
      .eq("is_senior", trainingHotelResults.value.is_senior)
      .not("completed_training_at", "is", null);

    if (error) throw error;

    if (data.length) {
      isTrainingComplete.value = true;
    }
  }
};

const setCompletedAtTrainingDate = async () => {
  isTrainingComplete.value = true;
  const { data, error } = await supabase
    .from("training_hotel_results")
    .select("id")
    .eq("trainee_id", trainee.value.id)
    .eq("hotel_id", hotel.value.id)
    .eq("is_senior", trainingHotelResults.value.is_senior)
    .not("started_training_at", "is", null);

  if (error) throw error;

  if (data.length === 0) {
    isTrainingComplete.value = false;
  }

  if (data.length > 1) {
    throw new Error(
      "Multiple trainings that were started for this hotel, seniority and employee found",
    );
    isTrainingComplete.value = false;
  }

  if (data.length === 1) {
    const trainingHotelResultsPayload = {
      completed_training_at: new Date().toISOString(),
    };

    const { error } = await supabase
      .from("training_hotel_results")
      .update(trainingHotelResultsPayload)
      .eq("id", data[0].id)
      .select();

    if (error) {
      isTrainingComplete.value = false;
      throw error;
    }
  }
};

/** ------- Handle Comments ------- */

/**
 * get comment when training is complete and if author or visible to others
 */
const fetchFeedbackComment = async () => {
  if (trainingHotelResults.value.completed_final_at) {
    const { data: feedbackData, error: feedbackError } = await supabase
      .from("training_feedback_comments")
      .select("*")
      .eq("training_hotel_results_id", trainingHotelResults.value.id)
      .eq("is_final_feedback", true);

    if (feedbackError) {
      throw feedbackError;
    }

    feedbackComment.value = feedbackData[0] ?? {};
  }
};

const reviewFeedbackComment = async () => {
  const { error } = await supabase
    .from("training_feedback_comments")
    .update(feedbackComment.value)
    .eq("id", feedbackComment.value.id);

  if (error) {
    throw error;
  }
};

const fetchReviewer = async () => {
  if (!trainingHotelResults.value.final_reviewer_id) {
    return;
  }

  try {
    const { data: reviewerData, error: reviewerError } = await supabase
      .from("employees")
      .select("id, name")
      .eq("id", trainingHotelResults.value.final_reviewer_id);

    if (reviewerError) {
      throw reviewerError;
    }

    reviewer.value = reviewerData[0];
  } catch (error) {
    console.error("Error fetching reviewer: ", error);
    snackbar.text =
      "Fehler beim Laden der Daten zur Person die das genehmigt hat.";
    snackbar.color = "error";
    snackbar.isOpen = true;
  }
};

/** -------- Results calculation -------- */

const totalPoints = computed(() => {
  let points = 0;
  if (taskExaminationForm.value && choiceOptions.value) {
    taskExaminationForm.value.forEach((category) => {
      const tasks = category.is_general
        ? category.training_general_tasks
        : category.hotel_specific_tasks;
      tasks.forEach((task) => {
        if (
          task.result?.length > 0 &&
          task.result[0].final_training_choice_id
        ) {
          const choiceId = task.result[0].final_training_choice_id;
          const choiceOption = choiceOptions.value.find(
            (option) => option.id === choiceId,
          );
          if (choiceOption && !task.is_crucial) {
            points += category.is_general
              ? choiceOption.point_general
              : choiceOption.point_specific;
          }
        }
      });
    });
  }
  return points;
});

const maxPoints = computed(() => {
  let points = 0;
  if (taskExaminationForm.value && choiceOptions.value) {
    taskExaminationForm.value.forEach((category) => {
      const tasks = category.is_general
        ? category.training_general_tasks
        : category.hotel_specific_tasks;
      const maxPointsPerTask = category.is_general
        ? Math.max(...choiceOptions.value.map((option) => option.point_general))
        : Math.max(
            ...choiceOptions.value.map((option) => option.point_specific),
          );
      points +=
        tasks.filter((task) => !task.is_crucial).length * maxPointsPerTask;
    });
  }
  return points;
});

const totalPercentage = computed(() => {
  return maxPoints.value ? (totalPoints.value / maxPoints.value) * 100 : 0;
});

const isFailedCrucialTask = computed(() => {
  let failed = false;
  taskExaminationForm.value?.forEach((category) => {
    const tasks = category.is_general
      ? category.training_general_tasks
      : category.hotel_specific_tasks;
    tasks.forEach((task) => {
      if (task.is_crucial && task.result?.length > 0) {
        const choiceId = task.result[0].final_training_choice_id;
        if (choiceId === 3 || choiceId === 4) {
          failed = true;
        }
      }
    });
  });
  return failed;
});

const progressMarkers = computed(() => [
  {
    value: "90-100%",
    gradingId: 1,
    isResult: !isFailedCrucialTask.value && totalPercentage.value >= 90,
    assessmentText: ASSESSMENT_RANGE_TEXTS["90-100%"],
    minPoints: Math.ceil((90 / 100) * maxPoints.value),
    maxPoints: maxPoints.value,
  },
  {
    value: "75-89%",
    gradingId: 2,
    isResult:
      !isFailedCrucialTask.value &&
      totalPercentage.value >= 75 &&
      totalPercentage.value < 90,
    assessmentText: ASSESSMENT_RANGE_TEXTS["75-89%"],
    minPoints: Math.ceil((75 / 100) * maxPoints.value),
    maxPoints: Math.ceil((89 / 100) * maxPoints.value),
  },
  {
    value: "50-75%",
    gradingId: 3,
    isResult:
      !isFailedCrucialTask.value &&
      totalPercentage.value >= 50 &&
      totalPercentage.value < 75,
    assessmentText: ASSESSMENT_RANGE_TEXTS["50-75%"],
    minPoints: Math.ceil((50 / 100) * maxPoints.value),
    maxPoints: Math.ceil((75 / 100) * maxPoints.value),
  },
  {
    value: "0-49%",
    gradingId: 4,
    isResult: !isFailedCrucialTask.value && totalPercentage.value < 50,
    assessmentText: ASSESSMENT_RANGE_TEXTS["0-49%"],
    minPoints: Math.ceil((0 / 100) * maxPoints.value),
    maxPoints: Math.ceil((49 / 100) * maxPoints.value),
  },
  {
    value: "⚠",
    gradingId: 5,
    isResult: isFailedCrucialTask.value,
    assessmentText: ASSESSMENT_RANGE_TEXTS.crucial,
    minPoints: null,
    maxPoints: null,
  },
]);

onMounted(async () => {
  try {
    const hotelId = hotel.value.id;
    const isSeniorShift = trainingHotelResults.value.is_senior;

    // get current User by user_id
    const { data: employee, error: employeeFetchingError } = await supabase
      .from("employees")
      .select("id, name")
      .eq("user_id", store.state.userInfo.id);

    if (employeeFetchingError) {
      throw employeeFetchingError;
    }

    currentUser.value = employee[0];

    if (trainingHotelResults.value.started_final_at) {
      traineeConfirmation.value = true;
      examinerConfirmation.value = true;
    }

    if (trainingHotelResults.value.completed_final_at) {
      examinationDate.value = getFormattedShiftDate(
        trainingHotelResults.value.completed_final_at,
      );
      signatureDate.value = new Date(
        trainingHotelResults.value.completed_final_at,
      ).toLocaleDateString("de");
    }

    // get choice options / points
    const { data: choiceOptionsResponse, error: pointsFetchError } =
      await supabase.from("training_result_choices").select("*");

    if (pointsFetchError) {
      throw error;
    }

    choiceOptions.value = choiceOptionsResponse;

    // get tasks and results
    let initialFetchingQuery = supabase
      .from("hotel_task_categories")
      .select(
        `*, hotel_specific_tasks(*, task:hotel_tasks(name)${
          trainingHotelResults.value.id
            ? ", result:training_hotel_task_results(*)"
            : ""
        }), training_general_tasks(*, task:hotel_tasks(name)${
          trainingHotelResults.value.id
            ? ", result:training_hotel_task_results(*)"
            : ""
        })`,
      )
      .neq("id", 13) // exclude "Leistungsnachweis" category
      .is("hotel_specific_tasks.is_in_training_test", true)
      .eq("hotel_specific_tasks.hotel_id", hotelId)
      .is("hotel_specific_tasks.is_senior", isSeniorShift)
      .is("hotel_specific_tasks.deleted_at", null)
      .is("training_general_tasks.deleted_at", null);

    if (trainingHotelResults.value.id) {
      initialFetchingQuery
        .eq(
          "hotel_specific_tasks.training_hotel_task_results.training_hotel_result_id",
          trainingHotelResults.value.id,
        )
        .eq(
          "training_general_tasks.training_hotel_task_results.training_hotel_result_id",
          trainingHotelResults.value.id,
        );
    }
    const { data: formStructure, error: fetchError } =
      await initialFetchingQuery;

    if (fetchError) {
      throw error;
    }

    const { data: examinerData, error: examinerError } = await supabase
      .from("employees")
      .select("id, name")
      .eq("id", trainingHotelResults.value.final_examiner_id);

    if (examinerError) {
      throw examinerError;
    }

    examiner.value = examinerData[0];

    await fetchFeedbackComment();
    await fetchReviewer();
    await fetchIsTrainingComplete();
    initializeTaskExaminationForm(formStructure);
  } catch (error) {
    console.error("Error fetching data: ", error);
    snackbar.text = "Fehler beim Laden der Daten. Bitte Formular neu laden.";
    snackbar.color = "error";
    snackbar.isOpen = true;
  }
});

const progressConfig = {
  width: "75px",
  height: "300px",
};
</script>

<style scoped>
:deep(.v-expansion-panel-text__wrapper) {
  padding: 0;
}

.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.fixed-stepper {
  position: fixed;
  top: 72px;
  left: 0;
  width: 100%;
  z-index: 9;
  background-color: white;
}

.view-content {
  overflow-y: auto;
  margin-top: 170px;
  max-height: calc(100% - 170px);
}

.battery-icon-wrapper {
  width: v-bind("progressConfig.width");
  height: v-bind("progressConfig.height");
  margin: 0 auto;

  .battery-bar {
    width: v-bind("progressConfig.height");
    transform: rotate(-90deg) translateX(-100%);
    transform-origin: top left;
  }
}

.point-range-element {
  height: calc(v-bind("progressConfig.height") / 5);
}
</style>
