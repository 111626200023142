<template>
  <div>
    <v-row class="ma-2 pa-2">
      <v-col cols="12">
        <v-card elevation="10">
          <v-card-text>
            <v-row>
              <v-col cols="3" align="left">
                <v-btn @click="changeDays('minus')">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" align="center">
                <div class="text-h5 my-2">
                  Schichtplan für Woche
                  {{
                    startDate.toLocaleDateString() +
                    " - " +
                    endDate.toLocaleDateString()
                  }}
                </div>
              </v-col>
              <v-col cols="3" align="right">
                <v-btn @click="changeDays('plus')">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="search" label="Suche"></v-text-field>
              </v-col>
              <v-col cols="8">
                <v-btn-toggle v-model="eventFilter">
                  <v-btn
                    size="small"
                    v-for="event in events"
                    :key="event.name"
                    :value="event.value"
                    @click="getEmployees"
                  >
                    <v-icon>{{ event.icon }}</v-icon>
                    {{ event.name }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="12">
                <v-card>
                  <v-card-text>
                    <v-data-table-virtual
                      :loading="tableLoading"
                      :search="search"
                      :headers="headers"
                      :items="employees"
                    >
                      <template
                        v-for="n in 7"
                        v-slot:[`item.${n}`]="{ item, value }"
                      >
                        <v-chip
                          v-if="value.is_shift"
                          variant="outlined"
                          size="small"
                          @click="editEvent(item, value, n)"
                        >
                          {{ value.event }}
                          <v-icon
                            class="ml-1"
                            size="small"
                            v-for="icon in value.addedEvents"
                            :icon="icon.icon"
                          ></v-icon>
                        </v-chip>
                        <v-icon
                          v-else
                          :icon="value.icon"
                          @click="editEvent(item, value, n)"
                        ></v-icon>
                      </template>
                    </v-data-table-virtual>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="eventEdit.dialog" width="1000">
      <v-card>
        <v-card-title>Einträge erstellen</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="5">
              <v-autocomplete
                :items="employees"
                v-model="eventEdit.eventData.employee_id"
                item-title="show_name"
                item-value="id"
                readonly
                variant="solo"
                label="Mitarbeiter"
              ></v-autocomplete>
            </v-col>
            <v-col cols="5">
              <v-autocomplete
                :items="hotels"
                v-model="eventEdit.eventData.hotel_id"
                item-title="name"
                item-value="id"
                readonly
                variant="solo"
                label="Hotel"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="eventEdit.eventData.formattedDate"
                label="Datum"
                type="date"
                readonly
                variant="solo"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <div>Ereignis</div>
              <v-btn
                size="small"
                :color="eventEdit.eventData[event.value] ? 'success' : null"
                v-for="event in events"
                rounded="xl"
                class="ma-1"
                :disabled="event.disabled"
                :prepend-icon="event.icon"
                @click="
                  chooseEvent(eventEdit.eventData, event, { added: false })
                "
                >{{ event.name }}</v-btn
              >
            </v-col>
            <v-col cols="2">
              <v-divider vertical></v-divider>
            </v-col>
            <v-col cols="5">
              <div>Zusätzliche Ereignisse</div>
              <v-btn
                size="small"
                rounded="xl"
                class="ma-1"
                :disabled="event.disabled"
                :color="eventEdit.eventData[event.value] ? 'success' : null"
                v-for="event in addedEventInfo"
                :prepend-icon="event.icon"
                @click="
                  chooseEvent(eventEdit.eventData, event, { added: true })
                "
                >{{ event.name }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup></script>
<script>
export default {
  name: "Shifts",
  data() {
    return {
      tableLoading: false,
      today: new Date(),
      forwardDays: 6,
      backDays: 0,
      dayRange: 7,
      hotels: [],
      startDate: new Date(),
      endDate: new Date(),
      eventEdit: {
        dialog: false,
        eventData: {},
      },
      weekdays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      employees: [],
      eventFilter: null,
      headers: [],
      chosenCity: null,
      search: null,
      cities: [],
      cityEmployees: [],
      addedEventInfo: [
        { name: "Verspätung", icon: "mdi-clock-alert", value: "is_late" },
        {
          name: "Zusatzschicht",
          icon: "mdi-table-plus",
          value: "is_additional_shift",
        },
        {
          name: "Schichtwechsel",
          icon: "mdi-swap-horizontal-bold",
          value: "has_changed_shift",
        },
        {
          name: "Einarbeitung",
          icon: "mdi-school",
          value: "is_training",
          disabled: true,
        },
      ],
      events: [
        { name: "Frei", icon: "mdi-home", value: "is_free", disabled: true },
        {
          name: "Freiwunsch",
          icon: "mdi-table-question",
          value: "is_free_wish",
        },
        {
          name: "Urlaub",
          icon: "mdi-beach",
          value: "is_holiday",
          disabled: true,
        },
        { name: "Krank", icon: "mdi-emoticon-sick", value: "is_sick" },
        { name: "Büro", icon: "mdi-office-building", value: "is_office_shift" },
        {
          name: "Unbezahlter Urlaub",
          icon: "mdi-currency-usd-off",
          value: "is_unpaid_holiday",
          disabled: true,
          editable: true,
        },
        {
          name: "Einarbeitung",
          icon: "mdi-school",
          value: "is_training",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    async getCities() {
      const response = await this.$store.dispatch("getSupabaseEntity", {
        select: "id,city",
        table: "cities",
        where: [{ type: "is", key: "hotel_city", value: true }],
      });
      this.cities = response.data;
    },
    async getHotels() {
      const response = await this.$store.dispatch("getSupabaseEntity", {
        select: "id,name,short_name",
        table: "hotels",
        where: [{ type: "eq", key: "email", value: true }],
        order: { key: "name", ascending: true },
      });
      this.hotels = response.data;
    },
    async getEmployees() {
      this.tableLoading = true;
      const response = await this.$store.dispatch("getSupabaseEntity", {
        select:
          "id,first_name,last_name,status,shift_days,employee_data_matching(*,employee_data_keys(*)),shifts(*,hotels(id,name,short_name)),employee_date_events(*)",
        table: "employees",
        where: [
          { type: "neq", key: "status", value: "Inaktiv" },
          {
            type: "in",
            key: "employee_data_matching.key_id",
            value: [5, 6, 7, 30, 59],
          },
          {
            type: "gte",
            key: "shifts.date",
            value: this.startDate.toISOString(),
          },
          {
            type: "lte",
            key: "shifts.date",
            value: this.endDate.toISOString(),
          },
          {
            type: "gte",
            key: "employee_date_events.date",
            value: this.startDate.toISOString(),
          },
          {
            type: "lte",
            key: "employee_date_events.date",
            value: this.endDate.toISOString(),
          },
        ],
      });
      response.data = response.data.filter((employee) => {
        return employee.employee_data_matching.some((data) => {
          return data.key_id === 6 && data.value_id === 2;
        });
      });
      response.data.forEach((employee) => {
        employee.show_name = employee.last_name + ", " + employee.first_name;
        employee.city = this.cities.find((city) => {
          return employee.employee_data_matching.some((data) => {
            return data.key_id === 5 && data.value_id === city.id;
          });
        }).city;
      });
      this.employees = [];
      response.data.forEach((employee) => {
        let employeeObject = {
          id: employee.id,
          show_name: employee.show_name,
          city: employee.city,
        };
        this.extractWeekDates(employee, employeeObject);
        if (this.eventFilter) {
          let pushElement = employee.employee_date_events.find((event) => {
            return event[this.eventFilter];
          });
          if (pushElement) {
            this.employees.push(employeeObject);
          }
        } else {
          this.employees.push(employeeObject);
        }
      });
      this.employees.sort((a, b) => {
        return a.show_name.localeCompare(b.show_name);
      });
      this.tableLoading = false;
    },
    async chooseEvent(eventData, event, added) {
      let searchArray = [];
      eventData.event = event.name;
      eventData.icon = event.icon;
      if (added.added) {
        searchArray = this.addedEventInfo;
      } else {
        searchArray = this.events;
      }
      for (var i = 0; i < searchArray.length; i++) {
        let eventValue = searchArray[i].value;
        if (!added.added) {
          eventData[eventValue] = false;
        }
        if (event.name === searchArray[i].name) {
          eventData[eventValue] = !eventData[eventValue];
        }
      }
      const payload = {
        employee_id: eventData.employee_id,
        date: eventData.formattedDate,
        hotel_id: eventData.hotel_id,
        is_free: eventData.is_free,
        is_free_wish: eventData.is_free_wish,
        is_holiday: eventData.is_holiday,
        is_sick: eventData.is_sick,
        is_office_shift: eventData.is_office_shift,
        is_unpaid_holiday: eventData.is_unpaid_holiday,
        is_late: eventData.is_late,
        is_additional_shift: eventData.is_additional_shift,
        is_training: eventData.is_training,
        has_changed_shift: eventData.has_changed_shift,
      };
      await this.$store.dispatch("upsertSupabaseEntity", {
        table: "employee_date_events",
        id: eventData.id,
        payload: payload,
      });
      if (event.value === "is_training") {
        const shiftPayload = {
          employee_id: eventData.employee_id,
          date: eventData.formattedDate,
          hotel_id: eventData.hotel_id,
          is_training: true,
          is_senior: eventData.is_senior,
        };
        await this.$store.dispatch("upsertSupabaseEntity", {
          table: "shifts",
          id: eventData.shift_id,
          payload: shiftPayload,
        });
      }
      this.getEmployees();
    },
    extractWeekDates(employee, employeeObject) {
      const dates = [];
      const days = this.backDays + this.forwardDays;
      for (let i = 0; i <= days; i++) {
        let index = i + 1;
        let addedEventInfoArray = [];
        var date = new Date(this.today);
        date.setDate(this.startDate.getDate() + i);
        let pushedAlready = false;
        employee.shifts.forEach((shift) => {
          if (
            new Date(shift.date).toLocaleDateString() ===
            date.toLocaleDateString()
          ) {
            employeeObject[index] = {
              date: date.toLocaleDateString(),
              event: shift.hotels.short_name,
              hotel_id: shift.hotels.id,
              icon: "mdi-domain",
              is_shift: true,
            };
            pushedAlready = true;
          }
        });
        employee.employee_date_events.forEach((event) => {
          if (
            new Date(event.date).toLocaleDateString() ===
            date.toLocaleDateString()
          ) {
            let eventName = null;
            let icon = null;
            let value = null;
            if (event.is_free) {
              eventName = "Frei";
              icon = "mdi-home";
              value = "is_free";
            } else if (event.is_free_wish) {
              eventName = "Freiwunsch";
              icon = "mdi-table-question";
              value = "is_free_wish";
            } else if (event.is_holiday) {
              eventName = "Urlaub";
              icon = "mdi-beach";
              value = "is_holiday";
            } else if (event.is_sick) {
              eventName = "Krank";
              icon = "mdi-emoticon-sick";
              value = "is_sick";
            } else if (event.is_office_shift) {
              eventName = "Büro";
              icon = "mdi-office-building";
              value = "is_office_shift";
            } else if (event.is_unpaid_holiday) {
              eventName = "Urlaub";
              icon = "mdi-currency-usd-off";
              value = "is_unpaid_holiday";
            }
            if (eventName && !pushedAlready) {
              employeeObject[index] = {
                date: date.toLocaleDateString(),
                event: eventName,
                icon: icon,
                value: value,
              };
              pushedAlready = true;
            }
            for (var i = 0; i < this.addedEventInfo.length; i++) {
              let eventValue = this.addedEventInfo[i].value;
              if (event[eventValue]) {
                addedEventInfoArray.push(this.addedEventInfo[i]);
              }
            }
          }
          if (employeeObject[index]) {
            employeeObject[index].addedEvents = addedEventInfoArray;
          }
        });
        if (!pushedAlready) {
          employeeObject[index] = {
            date: date.toLocaleDateString(),
            event: "Offen",
            icon: "mdi-checkbox-blank-outline",
          };
        }
      }
      return dates;
    },
    async editEvent(employee, value, n) {
      this.eventEdit.dialog = true;
      const [day, month, year] = value.date.split(".");

      // Format the date into YYYY-MM-DD
      const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
        2,
        "0",
      )}`;
      this.eventEdit.eventData = {
        employee_id: employee.id,
        name: employee.show_name,
        date: value.date,
        is_senior: true,
        formattedDate: formattedDate,
        event: value.event,
        hotel_id: value.hotel_id,
        icon: value.icon,
      };
      if (value.addedEvents) {
        value.addedEvents.forEach((event) => {
          this.eventEdit.eventData[event.value] = true;
        });
      }
      this.eventEdit.eventData[value.value] = true;
    },
    async changeDays(type) {
      if (type === "plus") {
        this.today.setDate(this.today.getDate() + 7);
      } else {
        this.today.setDate(this.today.getDate() - 7);
      }
      this.startDate = new Date(this.today);
      this.endDate = new Date(this.today);
      this.startDate.setDate(this.today.getDate() - this.backDays);
      this.endDate.setDate(this.today.getDate() + this.forwardDays);
      this.addHeaders();
      this.getEmployees();
    },
    addHeaders() {
      this.headers = [
        {
          title: "Name",
          align: "start",
          sortable: false,
          value: "show_name",
        },
      ];
      const days = this.backDays + this.forwardDays;
      for (let i = 0; i <= days; i++) {
        var date = new Date(this.today);
        date.setDate(this.startDate.getDate() + i);
        this.headers.push({
          title: date.toLocaleDateString(),
          value: (i + 1).toString(),
          align: "center",
        });
      }
    },
    // Your methods go here
    getNextMonday() {
      this.today = new Date();
      const currentDay = this.today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
      const daysUntilNextMonday = (8 - currentDay) % 7 || 7; // Calculate days until next Monday
      this.today.setDate(this.today.getDate() + daysUntilNextMonday); // Add days to get to the next Monday
      this.startDate = new Date(this.today);
      this.endDate = new Date(this.today);
      this.startDate.setDate(this.today.getDate() - this.backDays);
      this.endDate.setDate(this.today.getDate() + this.forwardDays);
      this.addHeaders();
    },
  },

  mounted() {
    this.getCities();
    this.getHotels();
    this.getNextMonday();
    this.getEmployees();
    // Code to run when the component is mounted goes here
  },
};
</script>

<style scoped>
/* Your component-specific styles go here */
</style>
