<template>
  <v-card flat>
    <v-tabs
      v-model="taskCategoryTab"
      fixed-tabs
      color="blue-grey-darken-1"
      class="px-1"
    >
      <v-tab value="generalTasks">Allgemein</v-tab>
      <v-tab value="hotelSpecificTasks">Hotelspezifisch</v-tab>
    </v-tabs>
    <v-window v-model="taskCategoryTab" disabled>
      <v-window-item value="generalTasks">
        <v-container fluid class="mx-0 px-1">
          <v-row>
            <v-col
              cols="12"
              align="center"
              v-for="category in store.getTaskTrainingForm.filter(
                (category) => category.is_general,
              )"
            >
              <v-expansion-panels static>
                <v-expansion-panel
                  :color="
                    getTaskCompletionInfo(category).isCompleted
                      ? 'blue-grey-darken-3'
                      : ''
                  "
                >
                  <v-expansion-panel-title>
                    <v-container fluid class="d-flex flex-column align-start">
                      <span style="font-size: 16px" class="mb-1">
                        {{ category.name }}
                      </span>
                      <v-progress-linear
                        :model-value="
                          (getCategoryProgress(category).completedBasic /
                            getCategoryProgress(category).total) *
                          100
                        "
                        color="grey"
                        rounded
                      />
                      <v-progress-linear
                        :model-value="
                          (getCategoryProgress(category).completedWithHelp /
                            getCategoryProgress(category).total) *
                          100
                        "
                        class="my-1"
                        color="blue-grey-darken-1"
                        rounded
                      />
                      <v-progress-linear
                        :model-value="
                          (getCategoryProgress(category).completedWithoutHelp /
                            getCategoryProgress(category).total) *
                          100
                        "
                        color="success"
                        rounded
                      />
                    </v-container>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <v-container class="pt-0">
                      <v-row no-gutters>
                        <v-col
                          cols="8"
                          offset="4"
                          sm="4"
                          offset-sm="2"
                          class="d-flex align-center pr-3"
                          style="justify-content: flex-end"
                        >
                          <span class="text-grey">grundlegend</span>
                        </v-col>
                        <v-col
                          cols="8"
                          offset="4"
                          sm="3"
                          offset-sm="0"
                          class="d-flex align-center pr-3"
                          style="justify-content: flex-end"
                        >
                          <span class="text-blue-grey-darken-1">mit Hilfe</span>
                        </v-col>
                        <v-col
                          cols="8"
                          offset="4"
                          sm="3"
                          offset-sm="0"
                          class="d-flex align-center pr-3"
                          style="justify-content: flex-end"
                        >
                          <span class="text-success">ohne Hilfe</span>
                        </v-col>
                      </v-row>
                      <v-row
                        align-content="start"
                        no-gutters
                        v-for="task in category.training_general_tasks"
                      >
                        <v-col
                          align="start"
                          cols="6"
                          sm="5"
                          class="mt-4 d-flex"
                        >
                          <v-icon
                            v-if="store.getTrainingShiftDates.length > 1"
                            class="mr-2 pt-2"
                            size="15"
                            @click="openTaskProgressChart(task)"
                            >mdi-chart-bar</v-icon
                          >
                          <span
                            :class="shouldHighlightTask(task) ? 'text-red' : ''"
                          >
                            {{ task.task.name }}
                          </span>
                        </v-col>
                        <v-col
                          cols="2"
                          sm="1"
                          class="d-flex"
                          style="justify-content: flex-end"
                        >
                          <v-checkbox
                            base-color="grey"
                            color="grey"
                            :model-value="task.selected === 1"
                            @update:modelValue="
                              updateTaskSelection(task, 1, $event)
                            "
                            disabled
                          />
                        </v-col>
                        <v-col
                          cols="2"
                          sm="1"
                          offset-sm="2"
                          class="d-flex"
                          style="justify-content: flex-end"
                        >
                          <v-checkbox
                            base-color="blue-grey-darken-1"
                            color="blue-grey-darken-1"
                            :model-value="task.selected === 2"
                            @update:modelValue="
                              updateTaskSelection(task, 2, $event)
                            "
                            disabled
                          />
                        </v-col>
                        <v-col
                          cols="2"
                          sm="1"
                          offset-sm="2"
                          class="d-flex"
                          style="justify-content: flex-end"
                        >
                          <v-checkbox
                            base-color="success"
                            color="success"
                            :model-value="task.selected === 3"
                            @update:modelValue="
                              updateTaskSelection(task, 3, $event)
                            "
                            disabled
                          />
                        </v-col>
                        <v-divider></v-divider>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>
      <v-window-item value="hotelSpecificTasks">
        <v-container fluid class="mx-0 px-1">
          <v-row>
            <v-col
              v-if="
                store.getTaskTrainingForm.filter(
                  (category) => !category.is_general,
                ).length === 0
              "
              class="my-2"
            >
              <h4>Keine Hotelspezifischen Aufgaben gefunden</h4>
            </v-col>
            <v-col
              cols="12"
              align="center"
              v-for="category in store.getTaskTrainingForm.filter(
                (category) => !category.is_general,
              )"
            >
              <v-expansion-panels static>
                <v-expansion-panel
                  :color="
                    getTaskCompletionInfo(category).isCompleted
                      ? 'blue-grey-darken-3'
                      : ''
                  "
                >
                  <v-expansion-panel-title>
                    <v-container fluid class="d-flex flex-column align-start">
                      <span style="font-size: 16px" class="mb-1">
                        {{ category.name }}
                      </span>
                      <v-progress-linear
                        :model-value="
                          (getCategoryProgress(category).completedBasic /
                            getCategoryProgress(category).total) *
                          100
                        "
                        color="grey"
                        rounded
                      />
                      <v-progress-linear
                        :model-value="
                          (getCategoryProgress(category).completedWithHelp /
                            getCategoryProgress(category).total) *
                          100
                        "
                        class="my-1"
                        color="blue-grey-darken-1"
                        rounded
                      />
                      <v-progress-linear
                        :model-value="
                          (getCategoryProgress(category).completedWithoutHelp /
                            getCategoryProgress(category).total) *
                          100
                        "
                        color="success"
                        rounded
                      />
                    </v-container>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <v-container class="pt-0">
                      <v-row no-gutters>
                        <v-col
                          cols="1"
                          class="d-flex align-center pr-3"
                          style="justify-content: flex-start"
                        >
                          <v-tooltip
                            text="Die Dienstanweisung kann durch das Tippen auf die Aufgabe geöffnet werden. Ab dem 2. Tag kann der die Fortschritthistorie einer Aufgabe durch das Tippen auf das Balkendiagramm geöffnet werden. "
                            open-on-click
                            :open-on-hover="false"
                            close-on-content-click
                            location="top"
                            v-model="showHelpTooltip"
                            @click:outside="showHelpTooltip = false"
                          >
                            <template v-slot:activator="{ props }">
                              <v-icon v-bind="props" size="22" class="ml-n1">
                                mdi-help-circle-outline
                              </v-icon>
                            </template>
                          </v-tooltip>
                        </v-col>
                        <v-col
                          cols="8"
                          offset="3"
                          sm="4"
                          offset-sm="1"
                          class="d-flex align-center pr-3"
                          style="justify-content: flex-end"
                        >
                          <span class="text-grey">grundlegend</span>
                        </v-col>
                        <v-col
                          cols="8"
                          offset="4"
                          sm="3"
                          offset-sm="0"
                          class="d-flex align-center pr-3"
                          style="justify-content: flex-end"
                        >
                          <span class="text-blue-grey-darken-1">mit Hilfe</span>
                        </v-col>
                        <v-col
                          cols="8"
                          offset="4"
                          sm="3"
                          offset-sm="0"
                          class="d-flex align-center pr-3"
                          style="justify-content: flex-end"
                        >
                          <span class="text-success">ohne Hilfe</span>
                        </v-col>
                      </v-row>
                      <v-row
                        align-content="start"
                        no-gutters
                        v-for="task in category.hotel_specific_tasks"
                      >
                        <v-col
                          align="start"
                          cols="6"
                          sm="5"
                          class="mt-4 d-flex"
                        >
                          <v-icon
                            v-if="store.getTrainingShiftDates.length > 1"
                            class="mr-2 pt-2"
                            size="15"
                            @click="openTaskProgressChart(task)"
                            >mdi-chart-bar</v-icon
                          >
                          <span
                            @click="openTaskExplanationDialog(task)"
                            :class="shouldHighlightTask(task) ? 'text-red' : ''"
                          >
                            {{ task.task.name }}
                          </span>
                        </v-col>
                        <v-col
                          cols="2"
                          sm="1"
                          class="d-flex"
                          style="justify-content: flex-end"
                        >
                          <v-checkbox
                            base-color="grey"
                            color="grey"
                            :model-value="task.selected === 1"
                            @update:modelValue="
                              updateTaskSelection(task, 1, $event)
                            "
                            disabled
                          />
                        </v-col>
                        <v-col
                          cols="2"
                          sm="1"
                          offset-sm="2"
                          class="d-flex"
                          style="justify-content: flex-end"
                        >
                          <v-checkbox
                            base-color="blue-grey-darken-1"
                            color="blue-grey-darken-1"
                            :model-value="task.selected === 2"
                            @update:modelValue="
                              updateTaskSelection(task, 2, $event)
                            "
                            disabled
                          />
                        </v-col>
                        <v-col
                          cols="2"
                          sm="1"
                          offset-sm="2"
                          class="d-flex"
                          style="justify-content: flex-end"
                        >
                          <v-checkbox
                            base-color="success"
                            color="success"
                            :model-value="task.selected === 3"
                            @update:modelValue="
                              updateTaskSelection(task, 3, $event)
                            "
                            disabled
                          />
                        </v-col>
                        <v-divider></v-divider>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>
    </v-window>
  </v-card>

  <v-dialog v-model="taskProgressChart.isOpen" max-width="800">
    <v-card rounded="xl">
      <v-card-title>
        <v-row class="pa-3">
          <v-col cols="10" sm="11">
            Fortschritt der Aufgabe "{{ taskProgressChart.task.task.name }}":
          </v-col>
          <v-col cols="2" sm="1" align="end">
            <v-btn
              @click="taskProgressChart.isOpen = false"
              icon
              size="x-small"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <span v-if="taskProgressChart.data.values.length === 0">
          Aufgabe wurde noch nicht geprüft.
        </span>
        <span
          v-else-if="taskProgressChart.data.values.length === 1"
          v-html="
            taskProgressChart.data.historyEntries[
              taskProgressChart.data.historyEntries.length - 1
            ]
          "
        ></span>
        <div v-else class="d-flex flex-column">
          <v-sparkline
            max="3"
            min="1"
            :labels="taskProgressChart.data.labels"
            :model-value="taskProgressChart.data.values"
            :gradient="taskProgressChart.data.gradient"
            :gradient-direction="'top'"
            line-width="2"
            padding="12"
            smooth
          ></v-sparkline>

          <v-expansion-panels class="my-2">
            <v-expansion-panel>
              <v-expansion-panel-title class="px-3">
                <h5>Historie</h5>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <div class="d-flex flex-column pa-3">
                  <span
                    v-for="(historyEntry, index) in taskProgressChart.data
                      .historyEntries"
                    :key="index"
                    v-html="historyEntry"
                  ></span>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="taskExplanationDialog.isOpen" max-width="600">
    <v-card>
      <v-card-title>{{ taskExplanationDialog.task?.task.name }}</v-card-title>
      <v-card-text
        v-html="taskExplanationDialog.task?.explanation"
      ></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue-grey-darken-3"
          @click="taskExplanationDialog.isOpen = false"
        >
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from "vue";
import { cloneDeep } from "lodash";
import { supabase } from "@/supabase";
import getFormattedShiftDate from "@/utils/getFormattedShiftDate";
import { useProgressFormStore } from "@/store/useProgressFormStore";

const store = useProgressFormStore();

const showHelpTooltip = ref(false);
const taskCategoryTab = ref("generalTasks");

const taskProgressChart = ref({
  task: null,
  trainingShiftDates: null,
  isOpen: false,
  data: {
    labels: [],
    values: [],
    gradient: ["#4CAF50", "#37474F", "#F5F5F5"],
    historyEntries: [],
  },
});

const taskExplanationDialog = ref({
  isOpen: false,
  task: null,
});

const openTaskExplanationDialog = (task) => {
  taskExplanationDialog.value.task = task;
  taskExplanationDialog.value.isOpen = true;
};

const openTaskProgressChart = (task) => {
  taskProgressChart.value.task = task;
  taskProgressChart.value.trainingShiftDates = store.getTrainingShiftDates;
  generateTaskProgressChartData();
  taskProgressChart.value.isOpen = true;
};

function generateTaskProgressChartData() {
  taskProgressChart.value.data.labels = [];
  taskProgressChart.value.data.values = [];
  const labels = [];
  const values = [];
  const historyEntries = [];
  let trainingState = null;

  const shiftDates = taskProgressChart.value.trainingShiftDates;
  const selectedTask = taskProgressChart.value.task;

  shiftDates.forEach((shiftDate, index) => {
    const isLastDate = index === shiftDates.length - 1;
    const progressEntry = selectedTask?.progress?.find(
      (entry) => getFormattedShiftDate(entry.created_at) === shiftDate,
    );

    if (progressEntry) {
      trainingState = progressEntry.training_progress_state_id;
    }

    if (!isLastDate) {
      trainingState && values.push(trainingState);
      values.length && labels.push(`Tag ${index + 1}`);
      if (progressEntry) {
        let statusText = "";
        let statusClass = "";
        if (trainingState === 1) {
          statusText = "grundlegend";
          statusClass = "text-grey";
        } else if (trainingState === 2) {
          statusText = "mit Hilfe";
          statusClass = "text-blue-grey-darken-1";
        } else if (trainingState === 3) {
          statusText = "ohne Hilfe";
          statusClass = "text-success";
        }
        historyEntries.push(
          `Tag ${
            index + 1
          }: Aufgabe wurde als <span class='${statusClass}'>${statusText}</span> markiert.`,
        );
      } else {
        historyEntries.push(`Tag ${index + 1}: Aufgabe wurde nicht geprüft.`);
      }
    }

    if (isLastDate && selectedTask.selected) {
      values.push(selectedTask.selected);
      labels.push(`Heute`);
      let statusText = "";
      let statusClass = "";
      if (selectedTask.selected === 1) {
        statusText = "grundlegend";
        statusClass = "text-grey";
      } else if (selectedTask.selected === 2) {
        statusText = "mit Hilfe";
        statusClass = "text-blue-grey-darken-1";
      } else if (selectedTask.selected === 3) {
        statusText = "ohne Hilfe";
        statusClass = "text-success";
      }
      historyEntries.push(
        `Heute: Aufgabe wurde als <span class='${statusClass}'>${statusText}</span> markiert.`,
      );
    }
  });

  taskProgressChart.value.data.labels = labels;
  taskProgressChart.value.data.values = values;
  taskProgressChart.value.data.historyEntries = historyEntries;
}

function getTaskCompletionInfo(category: object): {
  text: string;
  isCompleted: boolean;
} {
  const tasks = category.is_general
    ? category.training_general_tasks
    : category.hotel_specific_tasks;
  const completedTasks = tasks.filter((task) => task.progress?.length > 3);
  return {
    text: `(${completedTasks.length}/${tasks.length})`,
    isCompleted: completedTasks.length === tasks.length,
  };
}

const getCategoryProgress = (category) => {
  let completedBasic = 0;
  let completedWithHelp = 0;
  let completedWithoutHelp = 0;
  let total =
    category.training_general_tasks.length +
    category.hotel_specific_tasks.length;

  const categoryTasks = [
    ...category.training_general_tasks,
    ...category.hotel_specific_tasks,
  ];

  categoryTasks.forEach((task) => {
    const latestProgress =
      task.selected ||
      task.progress?.[task.progress.length - 1]?.training_progress_state_id ||
      0;
    if (latestProgress >= 1) completedBasic++;
    if (latestProgress >= 2) completedWithHelp++;
    if (latestProgress === 3) completedWithoutHelp++;
  });

  return { completedBasic, completedWithHelp, completedWithoutHelp, total };
};

const shouldHighlightTask = (task) => {
  // If it's day 1, do not highlight
  if (store.getTrainingShiftDates.length === 1) {
    return false;
  }

  // If something was selected today, do not highlight
  if (task.selected) {
    return false;
  }

  const previousDayIndex = store.getTrainingShiftDates.length - 2;

  const previousDayDate = store.getTrainingShiftDates[previousDayIndex];
  const hasPreviousProgress = task.progress?.some(
    (entry) => getFormattedShiftDate(entry.created_at) === previousDayDate,
  );

  // If no previous progress, highlight the task
  return !hasPreviousProgress;
};
</script>

<style scoped>
:deep(.v-slider-thumb__label) {
  font-size: 8px;
  height: 50px;
}
:deep(.v-expansion-panel-text__wrapper) {
  padding: 0;
}
:deep(.v-checkbox) {
  height: 60px;
  display: flex;
  flex-direction: column;
}
:deep(.v-checkbox .v-input__control) {
  height: 20px;
  display: block;
}
:deep(.v-checkbox .v-input__details) {
  display: block;
  margin-top: 20px;
}
/* Disable text selection */
.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
