<template>
  <v-card>
    <div class="ma-10">
      <v-alert class="ma-2" v-if="shift">
        <v-row>
          <v-col cols="12"
            ><strong class="text-h5">{{
              employee.data.employee_name
            }}</strong></v-col
          >
          <v-col cols="6">
            <span>
              <v-progress-circular
                class="mr-1"
                size="small"
                color="indigo"
                width="6"
                :model-value="
                  ((employee.data.shifts.length * 8) /
                    (employee.data.weekly_hours -
                      employee.data.holidays.length * 8)) *
                  100
                "
              >
              </v-progress-circular>
              {{ employee.data.shifts.length * 8 }} <span>/</span
              >{{
                employee.data.weekly_hours - employee.data.holidays.length * 8
              }}</span
            >
            <span class="mx-2"
              ><v-icon>mdi-home</v-icon
              >{{ employee.data.free_days.length }}</span
            >
            <span
              ><v-icon>mdi-beach</v-icon
              >{{ employee.data.holidays.length }}</span
            >
            <v-chip
              prepend-icon="mdi-alert-circle"
              v-if="employee.data.availability_note"
              size="x-small"
              >{{ employee.data.availability_note }}</v-chip
            >
          </v-col>
          <v-col cols="6">
            <template v-for="day in shiftDays">
              {{ day.short }}
              <v-icon
                :color="day.checked ? 'green-darken-2' : null"
                size="40px"
                class="mr-3"
                :icon="
                  employee.data.shift_days.find(
                    (shiftDay) => shiftDay === day.value,
                  ) >= 0
                    ? 'mdi-checkbox-marked'
                    : 'mdi-checkbox-blank-outline'
                "
              >
              </v-icon>
            </template>
          </v-col>
          <v-col cols="6">
            <v-text-field
              readonly
              v-model="currentMonthDays"
              suffix="Tage im aktuellen Monat eingeplant"
              type="number"
              variant="solo"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-alert>
      <!-- ScheduleXCalendar component to display calendar -->
      <ScheduleXCalendar :calendar-app="calendarApp" />
    </div>
    <!-- Display a loading spinner while fetching events -->
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </v-card>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { ScheduleXCalendar } from "@schedule-x/vue";
import { createEventsServicePlugin } from "@schedule-x/events-service";
import { useStore } from "vuex";
import {
  createCalendar,
  createViewDay,
  createViewMonthAgenda,
  createViewMonthGrid,
  createViewWeek,
} from "@schedule-x/calendar";
import "@schedule-x/theme-default/dist/index.css";

// Props to receive employee data
const props = defineProps({
  employee: {
    type: Object,
    required: true,
  },
  shift: {
    type: Object,
  },
});

const shiftDays = [
  { value: 1, checked: false, short: "Mo" },
  { value: 2, checked: false, short: "Di" },
  { value: 3, checked: false, short: "Mi" },
  { value: 4, checked: false, short: "Do" },
  { value: 5, checked: false, short: "Fr" },
  { value: 6, checked: false, short: "Sa" },
  { value: 0, checked: false, short: "So" },
];

const specialEvents = [
  { name: "Verspätung", icon: "mdi-clock-alert", value: "is_late" },
  {
    name: "Zusatzschicht",
    icon: "mdi-table-plus",
    value: "is_additional_shift",
  },
  {
    name: "Schichtwechsel",
    icon: "mdi-swap-horizontal-bold",
    value: "has_changed_shift",
  },
  { name: "Frei", icon: "mdi-home", value: "is_free" },
  {
    name: "Freiwunsch",
    icon: "mdi-table-question",
    value: "is_free_wish",
  },
  { name: "Urlaub", icon: "mdi-beach", value: "is_holiday" },
  { name: "Krank", icon: "mdi-emoticon-sick", value: "is_sick" },
  { name: "Büro", icon: "mdi-office-building", value: "is_office_shift" },
  {
    name: "Unbezahlter Urlaub",
    icon: "mdi-currency-usd-off",
    value: "is_unpaid_holiday",
  },
];

// Vuex store for accessing events
const store = useStore();

// Loading state for async operations
const loading = ref(true);

// Initialize the events service plugin
const eventsServicePlugin = createEventsServicePlugin();

let currentMonthDays = ref(0);

// Calendar setup with default configurations
const today = new Date().toISOString().split("T")[0];
const calendarApp = createCalendar(
  {
    selectedDate: today,
    defaultView: "month-grid",
    views: [
      createViewDay(),
      createViewWeek(),
      createViewMonthGrid(),
      createViewMonthAgenda(),
    ],
    events: [],
    calendars: {
      hotel: {
        colorName: "hotel",
        lightColors: {
          main: "blue",
          container: "blue",
          onContainer: "white",
        },
        darkColors: {
          main: "#c0dfff",
          onContainer: "#dee6ff",
          container: "#426aa2",
        },
      },
      company: {
        colorName: "company",
        lightColors: {
          main: "red",
          container: "red",
          onContainer: "white",
        },
        darkColors: {
          main: "#c0dfff",
          onContainer: "#dee6ff",
          container: "#426aa2",
        },
      },
    },
  },

  [eventsServicePlugin], // Pass the plugin for event management
);

// Function to add events to the calendar
function addEventsToCalendar(events) {
  if (!calendarApp.eventsService) {
    console.error("Events service is not initialized.");
    return;
  }
  events.forEach((event) => {
    calendarApp.eventsService.add({
      id: event.id,
      title: event.title || "Unnamed Event",
      start: event.start,
      end: event.end,
      style: event.style,
      calendarId: event.calendarId,
    });
  });
}

// Function to fetch and add employee-specific events
async function openEmployee() {
  loading.value = true;
  try {
    const startDate = new Date();
    const currentMonth = startDate.getMonth();
    const currentYear = startDate.getFullYear();
    console.log(currentMonth);
    const endDate = new Date();
    startDate.setDate(1);
    startDate.setMonth(startDate.getMonth() - 1);
    endDate.setMonth(startDate.getMonth() + 3, 0);

    const employee = props.employee.data;

    // Fetch employee events from Supabase
    const employeeEvents = await store.dispatch("getSupabaseEntity", {
      table: "employee_date_events",
      where: [
        { type: "eq", key: "employee_id", value: employee.employee_id },
        /*{ type: 'gte', key: 'date', value: startDate.toISOString() },
          { type: 'lte', key: 'date', value: endDate.toISOString() },
           */
      ],
    });

    // Fetch shift data for the employee from Supabase
    const shiftEvents = await store.dispatch("getSupabaseEntity", {
      table: "shifts_staging",
      select: "*,hotels(name,short_name)",
      where: [
        { type: "eq", key: "employee_id", value: employee.employee_id },
        /*{ type: 'gte', key: 'date', value: startDate.toISOString() },
          { type: 'lte', key: 'date', value: endDate.toISOString() },
           */
      ],
    });

    const companyEvents = await store.dispatch("getSupabaseEntity", {
      table: "company_dates",
      where: [
        /*{ type: 'gte', key: 'date', value: startDate.toISOString() },
          { type: 'lte', key: 'date', value: endDate.toISOString() },*/
      ],
    });

    const allEvents = employeeEvents.data
      .concat(shiftEvents.data)
      .concat(companyEvents.data);
    let calendarEvents = [];
    allEvents.forEach((event) => {
      if (specialEvents.find((specialEvent) => event[specialEvent.value])) {
        calendarEvents.push({
          id: event.id,
          title: specialEvents.find((specialEvent) => event[specialEvent.value])
            .name,
          start: event.date,
          end: event.date,
        });
      }
      if (event.hotel_short_name) {
        if (
          new Date(event.date).getMonth() === currentMonth &&
          new Date(event.date).getFullYear() === currentYear
        ) {
          currentMonthDays.value += 1;
        }
        calendarEvents.push({
          id: event.id,
          title: event.hotels.name,
          start: event.date,
          end: event.date,
          calendarId: "hotel",
        });
      }
      if (event.type == "holiday_block") {
        calendarEvents.push({
          id: event.id,
          title: "Urlaubssperre",
          start: event.date,
          end: event.date,
          calendarId: "company",
        });
      }
    });

    // Add events to the calendar
    addEventsToCalendar(calendarEvents);
  } catch (error) {
    console.error("Error fetching events:", error);
  } finally {
    loading.value = false;
  }
}

// Call openEmployee function once the component is mounted
onMounted(() => {
  openEmployee();
});
</script>
