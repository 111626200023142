<template>
  <v-card flat>
    <v-tabs
      v-model="taskCategoryTab"
      fixed-tabs
      color="blue-grey-darken-1"
      class="px-1"
    >
      <v-tab value="generalTasks">Allgemein</v-tab>
      <v-tab value="hotelSpecificTasks">Hotelspezifisch</v-tab>
    </v-tabs>
    <v-window v-model="taskCategoryTab" disabled>
      <v-window-item value="generalTasks">
        <v-container fluid class="mx-0 px-1">
          <v-row>
            <v-col
              cols="12"
              align="center"
              v-for="category in taskExaminationForm.filter(
                (category) => category.is_general,
              )"
            >
              <v-expansion-panels static>
                <v-expansion-panel
                  :title="`${category.name} ${
                    getTaskCompletionInfo(category).text
                  }`"
                  :color="
                    getTaskCompletionInfo(category).isCompleted
                      ? 'blue-grey-darken-3'
                      : ''
                  "
                >
                  <v-expansion-panel-text>
                    <v-container>
                      <v-row
                        align-content="start"
                        no-gutters
                        v-for="task in category.training_general_tasks"
                      >
                        <v-col align="start" cols="6">
                          {{ task.task.name }}
                        </v-col>
                        <v-col cols="6" class="pl-2 no-select">
                          <v-slider
                            readonly
                            class="mt-n1"
                            show-ticks="always"
                            step="1"
                            :max="2"
                            tick-size="4"
                            thumb-label
                            :model-value="
                              mapOptionIdToSliderValue(
                                task.result?.[0]
                                  ?.intermediary_training_choice_id,
                              )
                            "
                            :color="
                              getSliderColor(
                                task.result?.[0]
                                  ?.intermediary_training_choice_id,
                              )
                            "
                          >
                            <template #thumb-label="{ modelValue }">
                              {{ thumbLabels[modelValue] }}
                            </template>
                          </v-slider>
                        </v-col>
                        <v-divider></v-divider>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>
      <v-window-item value="hotelSpecificTasks">
        <v-container fluid class="mx-0 px-1">
          <v-row>
            <v-col
              v-if="
                taskExaminationForm.filter((category) => !category.is_general)
                  .length === 0
              "
              class="my-2"
            >
              <h4>Keine Hotelspezifischen Aufgaben gefunden</h4>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              md="8"
              offset="md-2"
              align="center"
              v-for="category in taskExaminationForm.filter(
                (category) => !category.is_general,
              )"
            >
              <v-expansion-panels static>
                <v-expansion-panel
                  :title="`${category.name} ${
                    getTaskCompletionInfo(category).text
                  }`"
                  :color="
                    getTaskCompletionInfo(category).isCompleted
                      ? 'blue-grey-darken-3'
                      : ''
                  "
                >
                  <v-expansion-panel-text>
                    <v-container>
                      <v-row
                        align-content="start"
                        no-gutters
                        v-for="task in category.hotel_specific_tasks"
                      >
                        <v-col align="start" cols="6">
                          {{ task.task.name }}
                        </v-col>
                        <v-col cols="6" class="pl-2">
                          <v-slider
                            readonly
                            class="mt-n1"
                            show-ticks="always"
                            step="1"
                            :max="2"
                            tick-size="3"
                            thumb-label
                            :model-value="
                              mapOptionIdToSliderValue(
                                task.result?.[0]
                                  ?.intermediary_training_choice_id,
                              )
                            "
                            :color="
                              getSliderColor(
                                task.result?.[0]
                                  ?.intermediary_training_choice_id,
                              )
                            "
                          >
                            <template #thumb-label="{ modelValue }">
                              {{ thumbLabels[modelValue] }}
                            </template>
                          </v-slider>
                        </v-col>
                        <v-divider></v-divider>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script setup>
import { ref, computed } from "vue";
import { INTERMEDIARY_EXAMINATION_FORM_THUMB_LABELS } from "./constants.js";

// Define props
const props = defineProps({
  taskExaminationForm: Array,
  isCompleted: Boolean,
});

const taskCategoryTab = ref("generalTasks");
const taskExaminationForm = ref(props.taskExaminationForm);
const isCompleted = ref(props.isCompleted);

const thumbLabels = computed(() => INTERMEDIARY_EXAMINATION_FORM_THUMB_LABELS);

function getTaskCompletionInfo(category) {
  const tasks = category.is_general
    ? category.training_general_tasks
    : category.hotel_specific_tasks;
  const completedTasks = tasks.filter(
    (task) =>
      task.result?.length > 0 && task.result[0].intermediary_training_choice_id,
  );
  return {
    text: `(${completedTasks.length}/${tasks.length})`,
    isCompleted: completedTasks.length === tasks.length,
  };
}

function mapOptionIdToSliderValue(optionId) {
  if (!optionId) return null;
  if ((optionId < 1 && optionId > 4) || optionId === 3) {
    throw new Error("Invalid choice option id");
  }
  switch (optionId) {
    case 1:
      return 0;
    case 2:
      return 1;
    case 4:
      return 2;
    default:
      return null;
  }
}

function getSliderColor(choiceId) {
  const sliderValue = mapOptionIdToSliderValue(choiceId);
  switch (sliderValue) {
    case 0:
      return "green";
    case 1:
      return "yellow";
    case 2:
      return "orange";
    default:
      return "grey";
  }
}
</script>

<style scoped>
:deep(.v-slider-thumb__label) {
  font-size: 8px;
  height: 50px;
}
:deep(.v-expansion-panel-text__wrapper) {
  padding: 0;
}
/* Disable text selection */
.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
