<template>
  <h3 class="ma-2">Hotel Location</h3>
  <v-card>
    <v-card-text class="mt-3">
      <v-row v-for="(location, index) in hotelLocations" class="my-n10">
        <v-col cols="12">
          <v-text-field
            variant="outlined"
            density="compact"
            append-icon="mdi-delete"
            @click:append="deleteLocation(location, index)"
            v-model="location.city"
            :disabled="location.hotels.length > 0"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-col align="center">
      <v-btn
        @click="
          hotelLocations.push({ city: null, hotels: [], hotel_city: true })
        "
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn @click="saveLocations()" class="ml-2" :loading="loading">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-col>
  </v-card>
</template>

<script setup>
import draggable from "vuedraggable";
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      hotelLocations: [],
    };
  },
  methods: {
    async getHotelLocations() {
      var queryObject = {
        table: "cities",
        select: "*,hotels(name)",
        where: [
          {
            type: "is",
            key: "hotel_city",
            value: true,
          },
        ],
        order: {
          key: "id",
          ascending: true,
        },
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.hotelLocations = response.data;
    },
    async deleteLocation(location, index) {
      if (location.id) {
        await this.$store.dispatch("deleteSupabaseEntity", {
          id: location.id,
          table: "cities",
        });
      }
      this.hotelLocations.splice(index, 1);
    },
    async saveLocations() {
      var table = "cities";
      var payload = this.hotelLocations;
      this.loading = true;
      var payload = JSON.parse(JSON.stringify(payload));
      var upsertArray = [];
      for (var i = 0; i < payload.length; i++) {
        delete payload[i].hotels;
        if (payload[i].id) {
          upsertArray.push(payload[i]);
        } else {
          var queryObject = {
            table: table,
            payload: payload[i],
          };
          await this.$store.dispatch("createSupabaseEntity", queryObject);
        }
      }
      var queryObject = {
        table: table,
        payload: upsertArray,
      };
      await this.$store.dispatch("upsertSupabaseEntity", queryObject);
      await this.getHotelLocations();
      this.loading = false;
    },
  },
  async mounted() {
    this.getHotelLocations();
  },
};
</script>
