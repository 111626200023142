<template>
  <v-card
    :class="isEmbedded ? '' : 'pa-4'"
    :elevation="isEmbedded ? 0 : 6"
    :flat="isEmbedded"
  >
    <v-card-title v-if="!isEmbedded"
      >Feedbackbogen zur Einarbeitung</v-card-title
    >
    <v-card-text>
      <v-form v-model="formIsValid" ref="feedbackFormRef" readonly>
        <div class="text-subtitle-1 mt-2">
          <strong>Systemkenntnisse des Trainees</strong>
        </div>
        <v-radio-group
          v-model="feedbackForm.systemKnowledge"
          :rules="[(v) => !!v || 'Bitte auswählen']"
        >
          <v-radio
            label="Anfänger (keine Vorkenntnisse)"
            value="beginner"
            color="blue-grey-darken-2"
          ></v-radio>
          <v-radio
            label="Grundlegend (braucht noch Hilfe beim Navigieren)"
            value="basic"
            color="blue-grey-darken-2"
          ></v-radio>
          <v-radio
            label="Fortgeschritten (arbeitet sicher mit dem System)"
            value="advanced"
            color="blue-grey-darken-2"
          ></v-radio>
          <v-radio
            label="Experte (keine Schulung des Systems notwendig)"
            value="expert"
            color="blue-grey-darken-2"
          ></v-radio>
        </v-radio-group>

        <div class="text-subtitle-1">
          <strong
            >Empfangstätigkeiten, die der Trainee selbständig durchführen kann /
            durchgeführt hat</strong
          >
        </div>
        <div
          v-for="(option, index) in receptionTasks"
          :key="index"
          class="d-flex align-center justify-start"
        >
          <div class="d-flex align-center">
            <v-checkbox-btn
              :label="option.label"
              :value="option.value"
              hide-details
              multiple
              readonly
              color="blue-grey-darken-2"
              v-model="feedbackForm.receptionTasks.selected"
            />
            <v-text-field
              v-if="
                feedbackForm.receptionTasks.selected.includes('checkIn') &&
                option.value === 'checkIn'
              "
              v-model.number="feedbackForm.receptionTasks.quantities.checkIn"
              style="margin-left: 18px"
              placeholder="Anzahl"
              type="number"
              variant="outlined"
              density="compact"
              hide-details
              min="0"
              max-width="150"
              :rules="[(v) => !!v]"
            ></v-text-field>
            <v-text-field
              v-if="
                feedbackForm.receptionTasks.selected.includes('checkOut') &&
                option.value === 'checkOut'
              "
              v-model.number="feedbackForm.receptionTasks.quantities.checkOut"
              class="ml-2 mt-1"
              placeholder="Anzahl"
              type="number"
              variant="outlined"
              density="compact"
              hide-details
              min="0"
              max-width="150"
              :rules="[(v) => !!v]"
            ></v-text-field>
          </div>
        </div>

        <div class="text-subtitle-1 mt-4">
          <strong>
            Welche Notfall- & Sicherheitsrelevanten Themen spezifisch zum Hotel
            wurden gezeigt?
          </strong>
        </div>
        <v-textarea
          v-model="feedbackForm.hotelSpecificSafetyTopics"
          placeholder="Stichpunktartige Auflistung wie z.B. Interface Server, Sprinkleranlage, etc."
          auto-grow
          hide-details
          rows="3"
        ></v-textarea>

        <div class="text-subtitle-1 mt-6">
          <strong>
            Bei welchen Aufgaben zur Dienstleistungserbringung hat der Trainee
            noch die meisten Herausforderungen?
          </strong>
        </div>
        <v-textarea
          v-model="feedbackForm.challenges"
          placeholder="Stichpunktartige Auflistung"
          auto-grow
          hide-details
          rows="3"
        ></v-textarea>

        <div class="text-subtitle-1 mt-6">
          <strong>Stand der Dienstanweisung</strong>
        </div>
        <v-radio-group
          v-model="feedbackForm.serviceInstructions"
          :rules="[(v) => !!v || 'Bitte auswählen']"
        >
          <v-radio
            label="aktuell keine Änderung notwendig"
            value="uptodate"
            color="blue-grey-darken-2"
          ></v-radio>
          <v-radio
            label="kleinere Abweichungen"
            value="minorDeviation"
            color="blue-grey-darken-2"
          ></v-radio>
          <v-radio
            label="muss angepasst werden"
            value="needsUpdate"
            color="blue-grey-darken-2"
          ></v-radio>
        </v-radio-group>

        <div
          class="text-subtitle-1 mb-2 mt-n4"
          v-if="
            feedbackForm.serviceInstructions &&
            feedbackForm.serviceInstructions !== 'uptodate'
          "
        >
          <strong>
            Welche Themen sind betroffen und was muss geändert werden?
          </strong>
          <v-textarea
            v-model="feedbackForm.serviceInstructionsText"
            placeholder="Stichpunktartige Auflistung wie z.B. Aufgaben, Pfade, Informationen"
            auto-grow
            rows="3"
            :rules="[(v) => !!v?.trim() || 'Bitte ausführen']"
          ></v-textarea>
        </div>

        <div class="text-subtitle-1">
          <strong>Beurteilung des Arbeits- und Sozialverhaltens</strong>
          <br />
          <em
            >(1) sehr gut; (2) gut; (3) befriedigend; (4) ausreichend; (5)
            mangelhaft</em
          >
          <v-checkbox-btn
            v-model="feedbackForm.workBehavior.onTime"
            label="Trainee war pünktlich"
            hide-details
            multiple
            readonly
            color="blue-grey-darken-2"
            class="my-1"
          />
          <v-checkbox-btn
            v-model="feedbackForm.workBehavior.dressCode"
            readonly
            label="Dresscode erfüllt (gem. Bellcaptain Standard)"
            hide-details
            color="blue-grey-darken-2"
            class="my-1"
          />
          <v-textarea
            v-if="feedbackForm.workBehavior.dressCode !== true"
            v-model="feedbackForm.workBehavior.dressCodeComment"
            readonly
            placeholder="Was war nicht gemäß dem Dresscode?"
            auto-grow
            rows="3"
            :rules="[(v) => !!v?.trim() || 'Bitte ausführen']"
          />
          <v-col
            cols="12"
            sm="9"
            class="no-select pt-0"
            v-for="(task, index) in workBehaviorTasks"
            :key="index"
          >
            <div>{{ task.label }}</div>
            <v-checkbox-btn
              v-if="
                task.value === 'guestBehavior' || task.value === 'pcKnowledge'
              "
              v-model="feedbackForm.workBehavior[`${task.value}Tested`]"
              readonly
              label="Konnte überprüft werden"
              hide-details
              color="blue-grey-darken-2"
              class="my-1 ml-n3"
            />
            <v-slider
              v-if="
                (task.value !== 'guestBehavior' &&
                  task.value !== 'pcKnowledge') ||
                feedbackForm.workBehavior[`${task.value}Tested`]
              "
              readonly
              class="mt-n1"
              v-model="feedbackForm.workBehavior[task.value]"
              show-ticks="always"
              step="1"
              :max="5"
              :min="1"
              tick-size="4"
              thumb-label
              :color="
                feedbackForm.workBehavior[task.value]
                  ? 'blue-grey-darken-2'
                  : 'grey'
              "
              :ticks="['1', '2', '3', '4', '5']"
              @end="feedbackForm.workBehavior[task.value] = $event"
              :rules="[(v) => !!v || 'Bitte auswählen']"
            >
              <template #thumb-label="{ modelValue }">
                {{ workBehaviourLabels[modelValue] }}
              </template>
            </v-slider>
            <v-divider class="mt-0"></v-divider>
          </v-col>
        </div>

        <div class="text-subtitle-1 mt-2">
          <strong>Wie viele Tage benötigt der Trainee noch?</strong>
          <v-radio-group
            v-model="feedbackForm.daysNeeded"
            :rules="[(v) => !!v || 'Bitte auswählen']"
          >
            <v-radio
              label="0-1"
              value="0-1"
              color="blue-grey-darken-2"
            ></v-radio>
            <v-radio
              label="1-2"
              value="1-2"
              color="blue-grey-darken-2"
            ></v-radio>
            <v-radio
              label="2-3"
              value="2-3"
              color="blue-grey-darken-2"
            ></v-radio>
            <v-radio
              label="3-4"
              value="3-4"
              color="blue-grey-darken-2"
            ></v-radio>
            <v-radio
              label="4-5"
              value="4-5"
              color="blue-grey-darken-2"
            ></v-radio>
            <v-radio label="5+" value="5+" color="blue-grey-darken-2"></v-radio>
          </v-radio-group>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import { reactive, ref, computed } from "vue";
import { useProgressFormStore } from "@/store/useProgressFormStore";
import { storeToRefs } from "pinia";

const store = useProgressFormStore();

const props = defineProps({
  isEmbedded: Boolean,
});

const isEmbedded = ref(props.isEmbedded);

const { feedbackForm, formIsValid } = storeToRefs(store);

const receptionTasks = ref([
  { label: "Check-in", value: "checkIn" },
  { label: "Check-out", value: "checkOut" },
  { label: "Leistungen buchen & stornieren", value: "bookCancelServices" },
  { label: "Fixleistungen ändern & setzen", value: "modifySetFixedServices" },
  { label: "Rechnungen ändern & erstellen", value: "modifyCreateInvoices" },
  { label: "Zahlungen buchen & stornieren", value: "bookCancelPayments" },
  { label: "Zimmerplan aufrufen & lesen", value: "accessReadRoomPlan" },
  { label: "Kategorieplan aufrufen & lesen", value: "accessReadCategoryPlan" },
  { label: "Kartencodierung System & offline", value: "cardEncoding" },
  { label: "Room Move durchführen", value: "roomMove" },
  { label: "Reservierung / Walk-in anlegen", value: "createReservationWalkIn" },
  { label: "Gäste im Haus", value: "guestsInHouse" },
  { label: "Hotelstatus aufrufen & lesen", value: "accessReadHotelStatus" },
  { label: "Listen & Reporting", value: "listsReporting" },
]);

const workBehaviorTasks = ref([
  {
    label: "Eigeninitiative (z.B. selbstständige Arbeitsweise)",
    value: "initiative",
  },
  { label: "Aufmerksamkeit", value: "attention" },
  { label: "Motivation", value: "motivation" },
  {
    label: "Verhalten gegenüber Kollegen / Kunden",
    value: "colleagueBehavior",
  },
  { label: "Verhalten gegenüber Gästen", value: "guestBehavior" },
  { label: "PC Kenntnisse", value: "pcKnowledge" },
]);

const workBehaviourLabels = computed(() => {
  return {
    1: "sehr gut",
    2: "gut",
    3: "befriedigend",
    4: "ausreichend",
    5: "mangelhaft",
  };
});
</script>

<style scoped>
:deep(.v-slider-thumb__label) {
  font-size: 12px;
  height: 50px;
}
/* Disable text selection */
.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
