<template>
  <div>
    <v-row class="ma-2 pa-2">
      <v-col cols="12">
        <v-card elevation="10">
          <v-card-text>
            <v-row class="mb-3">
              <v-col cols="3" align="left">
                <v-btn @click="changeDays('minus')">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" align="center">
                <div class="text-h5 my-2">
                  Schichtplan für Woche
                  {{
                    startDate.toLocaleDateString() +
                    " - " +
                    endDate.toLocaleDateString()
                  }}
                </div>
              </v-col>
              <v-col cols="3" align="right">
                <v-btn @click="changeDays('plus')">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="4"
                v-for="city in hotelCities"
                :key="city.id"
                class="ma-n2"
              >
                <v-card elevation="10">
                  <v-card-title>
                    {{ city.city }}
                  </v-card-title>
                  <v-card-text>
                    <v-btn
                      size="small"
                      rounded="lg"
                      :color="getButtonColor(hotel)"
                      v-for="hotel in city.hotels"
                      @click="
                        selectedHotel = hotel.id;
                        isSenior = true;
                        loadHotel(hotel);
                      "
                      :key="hotel.id"
                      :append-icon="hotel.progress == 100 ? 'mdi-check' : null"
                    >
                      {{ hotel.short_name }}
                      {{
                        hotel.completeShifts ? hotel.completeShifts.length : 0
                      }}
                      / {{ hotel.shiftDays }}
                      <span v-if="false && hotel.progress != 100"
                        >{{ hotel.progress }}%</span
                      >
                      <v-chip
                        class="ml-1"
                        color="blue-grey"
                        variant="elevated"
                        size="x-small"
                        prepend-icon="mdi-school"
                        v-if="
                          hotel.trainingShifts &&
                          hotel.trainingShifts.length > 0
                        "
                        >{{ hotel.trainingShifts.length }}</v-chip
                      >
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-btn
              :color="isSenior ? 'primary' : null"
              @click="
                isSenior = true;
                loadHotel();
              "
              >Senior</v-btn
            >
            <v-btn
              :color="isSenior ? null : 'primary'"
              v-if="hotelObject.bonus_points_clerk"
              @click="
                isSenior = false;
                loadHotel();
              "
              >Clerk</v-btn
            >
            <v-btn
              :color="isTraining ? 'primary' : null"
              @click="
                isTraining = !isTraining;
                loadHotel();
              "
              >Einarbeitung
              <v-chip class="ml-1" size="x-small" color="green">{{
                chosenHotel.trainingShifts
                  ? chosenHotel.trainingShifts.length
                  : 0
              }}</v-chip></v-btn
            >
            <v-row>
              <v-col cols="9">
                <v-data-table-virtual
                  density="compact"
                  :headers="shiftPlan.headers"
                  :items="shiftPlan.shifts"
                >
                  <template #item.employee="{ item }">
                    <template v-if="isTraining">
                      <v-autocomplete
                        clearable
                        class="mt-2"
                        v-model="item.employee"
                        :items="allEmployees"
                        item-title="name"
                        returnObject
                        variant="outlined"
                        density="compact"
                        append-icon="mdi-content-save"
                        append-inner-icon="mdi-close"
                        @click:append-inner="removeTraining(item)"
                        @click:append="
                          item.employee.employee_id = item.employee.id;
                          item.is_training = true;
                          saveShift(item, item.employee);
                        "
                      ></v-autocomplete>
                    </template>

                    <div class="my-2" v-else>
                      <v-icon
                        class="mr-2"
                        @click="item.showSelection = !item.showSelection"
                        :icon="
                          item.showSelection ? 'mdi-arrow-up' : 'mdi-arrow-down'
                        "
                      ></v-icon>
                      <template
                        v-for="emp in item.employees"
                        :key="emp.employee_id"
                      >
                        <v-btn
                          size="x-small"
                          :append-icon="getIcon(item, emp)"
                          :disabled="checkAvailability(item, emp)"
                          v-if="
                            (item.showSelection ||
                              item.employeeId == emp.employee_id) &&
                            emp.status != 'Inaktiv'
                          "
                          :color="getEmployeeColor(item, emp)"
                          @click="
                            item.employeeId = emp.employee_id;
                            saveShift(item, emp);
                          "
                          >{{ emp.employee_name
                          }}<v-rating
                            readonly
                            class="ml-1 mb-1"
                            density="compact"
                            size="x-small"
                            length="5"
                            v-model="emp.level"
                          ></v-rating
                        ></v-btn>
                      </template>
                      <v-btn
                        @click="saveShift(item, null, item.employeeId)"
                        v-if="item.employeeId"
                        color="warning"
                        size="x-small"
                        >Entfernen</v-btn
                      >
                    </div>
                  </template>
                  <template #item.hotel_booking="{ item }">
                    <v-checkbox
                      label="Buchung"
                      v-model="item.hotel_booking"
                      @change="updateBooking(item)"
                    ></v-checkbox>
                  </template>
                </v-data-table-virtual>
              </v-col>
              <v-col cols="3">
                <v-card variant="outlined">
                  <v-card-title>Wochenstunden</v-card-title>
                  <v-card-text>
                    <template v-for="(employee, index) in employeeRecord">
                      <v-col cols="12" v-if="index < 10 || showAllEmployees">
                        <v-btn
                          style="text-decoration: underline; color: blue"
                          variant="text"
                          size="x-small"
                          @click="
                            employeeDialog.data = employee;
                            employeeDialog.dialog = true;
                          "
                          >{{ employee.employee_name }}</v-btn
                        >
                        <span>
                          <v-progress-circular
                            class="mr-1"
                            size="small"
                            color="indigo"
                            width="6"
                            :model-value="
                              ((employee.shifts.length * 8) /
                                (employee.weekly_hours -
                                  employee.holidays.length * 8)) *
                              100
                            "
                          >
                          </v-progress-circular>
                          {{ employee.shifts.length * 8 }} <span>/</span
                          >{{
                            employee.weekly_hours - employee.holidays.length * 8
                          }}</span
                        >
                        <span class="mx-2"
                          ><v-icon>mdi-home</v-icon
                          >{{ employee.free_days.length }}</span
                        >
                        <span
                          ><v-icon>mdi-beach</v-icon
                          >{{ employee.holidays.length }}</span
                        >
                        <v-chip
                          prepend-icon="mdi-alert-circle"
                          v-if="employee.availability_note"
                          size="x-small"
                          >{{ employee.availability_note }}</v-chip
                        >
                      </v-col>
                    </template>
                    <v-btn
                      size="small"
                      @click="showAllEmployees = !showAllEmployees"
                    >
                      <v-icon>{{
                        showAllEmployees ? "mdi-arrow-up" : "mdi-arrow-down"
                      }}</v-icon>
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="employeeDialog.dialog" width="1200px">
      <EmployeeDialog :employee="employeeDialog" :shift="true" />
    </v-dialog>
    <!-- Your template code goes here -->
  </div>
  <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
    <div>{{snackbar.data}}</div>
    <div v-if="snackbar.error">{{snackbar.error}}</div>
    <div v-else>Erfolgreich synchronisiert</div>
    <template v-slot:actions>
      <v-btn
        variant="text"
        @click="snackbar.show = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
import EmployeeDialog from "./EmployeeDialog.vue";
</script>
<script>
export default {
  name: "Shifts",
  data() {
    return {
      shifts: [],
      allEmployees: [],
      employeeRecord: [],
      chosenHotel: {},
      hotelObject: {},
      hotelCities: [],
      snackbar: {
        show:false,
        color: "success",
        error: "",
        data: null,
        timeout: 4000,
      },
      hotels: [],
      selectedHotel: null,
      isSenior: true,
      isTraining: false,
      isClerk: false,
      today: new Date(),
      forwardDays: 6,
      employeeDialog: {
        dialog: false,
        data: null,
      },
      backDays: 0,
      startDate: new Date(),
      endDate: new Date(),
      showAllEmployees: false,
      weekdays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      shiftPlan: {
        month: null,
        year: null,
        days: null,
        headers: [
          {
            title: "Datum",
            align: "start",
            sortable: false,
            value: "date",
          },
          {
            title: "Reguläre Hotelbuchung",
            align: "center",
            sortable: false,
            value: "hotel_booking",
          },
          {
            title: "Mitarbeiter",
            align: "start",
            sortable: false,
            value: "employee",
          },
        ],
        shifts: [],
      },
      // Your data properties go here
    };
  },
  methods: {
    getIcon(item, emp) {
      let day = new Date(item.dateDB).getDay();
      if (emp.shifts.find((shift) => shift == item.date)) {
        return "mdi-domain";
      } else if (emp.holidays.find((holiday) => holiday == item.date)) {
        return "mdi-beach";
      } else if (emp.free_days.find((freeDay) => freeDay == item.date)) {
        return "mdi-home";
      } else if (emp.is_free_wish.find((freeDay) => freeDay == item.date)) {
        return "mdi-table-question";
      } else if (
        emp.is_office_shift.find((officeDay) => officeDay == item.date)
      ) {
        return "mdi-office-building";
      } else if (
        emp.shift_days.find((shiftDay) => shiftDay == day) == undefined
      ) {
        return "mdi-thumb-down";
      } else {
        return null;
      }
    },
    getEmployeeColor(item, emp) {
      let day = new Date(item.dateDB).getDay();
      if (item.employeeId == emp.employee_id) {
        return "primary";
      }
      if (
        emp.is_free_wish.find((freeDay) => freeDay == item.date) ||
        emp.shift_days.find((shiftDay) => shiftDay == day) == undefined
      ) {
        return "orange-lighten-2";
      }
    },
    checkAvailability(item, emp) {
      const alreadyOnShift = emp.shifts.find((shift) => shift == item.date);
      const onHoliday = emp.holidays.find((holiday) => holiday == item.date);
      const onFreeDay = emp.free_days.find((freeDay) => freeDay == item.date);
      const onOfficeShift = emp.is_office_shift.find(
        (officeDay) => officeDay == item.date,
      );
      if (
        (alreadyOnShift || onHoliday || onFreeDay || onOfficeShift) &&
        item.employee != emp.employee_name
      ) {
        return true;
      } else {
        return false;
      }
    },
    async saveShift(item, emp, oldEmpId) {
      const shiftObject = {
        hotel_id: this.hotelObject.id,
        date: item.dateDB,
        is_training: item.is_training ? true : false,
        is_senior: this.isSenior ? true : false,
        employee_id: emp ? emp.employee_id : null,
      };
      await this.$store.dispatch("upsertSupabaseEntity", {
        table: "shifts",
        payload: shiftObject,
      });
      shiftObject.oldEmpId = oldEmpId
      this.synchronizeWithSheets(shiftObject);
      this.loadHotel();
      this.getProgress();
    },
    async synchronizeWithSheets(shiftObject) {
      var url = "https://script.google.com/macros/s/AKfycbwWK4sIlvvd8GfF_aADVEX58TTvCG79DylrUReDOgRqqo9_Yp8Nd3O3JmGmZBooXAFl/exec?task=updateShiftPlan&secret_key=TEW567LPOVBN267"
      var options = {
        "method":"POST",
        "body":JSON.stringify(shiftObject)
      }
      var response = await fetch(url,options)
      response = await response.json()
      var hotel = this.hotels.find(hotel => hotel.id == response.payload.hotel_id)
      var date = new Date(response.payload.date).toLocaleDateString("de-DE")
      this.snackbar.data = hotel.short_name + " am " + date
      if (response.error) {
        this.snackbar.color = "error"
        this.snackbar.error = response.error
        this.snackbar.timeout = 100000
      }
      else {
        this.snackbar.color = "success"
        this.snackbar.timeout = 1000
        this.snackbar.error = null
      }
      this.snackbar.show = true;
    },
    getButtonColor(hotel) {
      if (hotel.id == this.selectedHotel) {
        return "primary";
      } else if (hotel.progress == 100) {
        return "success";
      } else {
        return null;
      }
    },
    async getProgress() {
      await this.getHotels();
      for (var i = 0; i < this.hotelCities.length; i++) {
        for (var j = 0; j < this.hotelCities[i].hotels.length; j++) {
          var hotel = this.hotelCities[i].hotels[j];
          var shiftDaysSenior = hotel.shift_days_senior
            ? hotel.shift_days_senior.length
            : 0;
          var shiftDaysClerk = hotel.shift_days_clerk
            ? hotel.shift_days_clerk.length
            : 0;
          var shiftDays = shiftDaysSenior + shiftDaysClerk;

          var bookedShifts = hotel.shifts.filter(
            (shift) => shift.is_booked && !shift.is_training,
          ).length;

          if (bookedShifts > shiftDays) {
            shiftDays = bookedShifts;
          }

          var matched = 0;
          var toMatch = 0;
          this.hotelCities[i].hotels[j].completeShifts = [];
          this.hotelCities[i].hotels[j].trainingShifts = [];
          this.hotelCities[i].hotels[j].shiftDays = shiftDays;
          if (shiftDays > 0) {
            var completeShifts = hotel.shifts.filter(
              (shift) =>
                shift.employee_id && shift.is_booked && !shift.is_training,
            );
            var trainingShifts = hotel.shifts.filter(
              (shift) => shift.is_training,
            );
            var result = completeShifts.length / shiftDays;
            this.hotelCities[i].hotels[j].completeShifts = completeShifts;
            this.hotelCities[i].hotels[j].trainingShifts = trainingShifts;
            this.hotelCities[i].hotels[j].shiftDays = shiftDays;
            this.hotelCities[i].hotels[j].progress = Math.round(result * 100);
          } else {
            this.hotelCities[i].hotels[j].progress = 100;
          }
        }
      }
    },
    async updateBooking(shift) {
      let payload = {
        hotel_id: this.hotelObject.id,
        date: shift.dateDB,
        is_senior: this.isSenior,
        is_training: false,
        is_booked: shift.hotel_booking,
        check_id: 1,
      };
      await this.$store.dispatch("upsertSupabaseEntity", {
        table: "shifts",
        payload: payload,
      });
      this.getProgress();
      this.loadHotel();
    },
    async getHotels() {
      this.hotels = [];
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "cities",
        select:
          "*,hotels(name,short_name,id,bonus_points_senior,bonus_points_clerk,shift_days_senior,shift_days_clerk,shifts(*)))",
        where: [
          { type: "is", key: "hotels.email", value: true },
          { type: "is", key: "hotel_city", value: true },
          {
            type: "gte",
            key: "hotels.shifts.date",
            value: this.startDate.toISOString(),
          },
          {
            type: "lte",
            key: "hotels.shifts.date",
            value: this.endDate.toISOString(),
          },
        ],
      });
      response.data.forEach((city) => {
        city.hotels.sort((a, b) => a.name.localeCompare(b.name));
        city.hotels.forEach((hotel) => {
          this.hotels.push(hotel);
        });
      });
      this.hotelCities = response.data;
      this.hotelCities.sort((a, b) => b.hotels.length - a.hotels.length);
    },
    async removeTraining(shift) {
      await this.$store.dispatch("deleteSupabaseEntity", {
        table: "shifts",
        id: shift.shiftInfo.id,
      });
      this.chosenHotel.trainingShifts.pop();
      this.loadHotel();
    },
    async loadHotel(hotel) {
      let start = new Date(this.startDate);
      let end = new Date(this.endDate);

      this.shiftPlan.shifts = [];
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "hotels",
        select:
          "id,name,short_name,bonus_points_senior,bonus_points_clerk,shift_days_senior,shift_days_clerk,employees_hotels(*,employees!inner(id,name,first_name,last_name,status,employee_data_matching(*),shifts(*),employee_date_events(*))),shifts(*,employees(name,first_name,last_name,status,employee_data_matching(*),shift_days,availability_note))",
        where: [
          { type: "eq", key: "id", value: this.selectedHotel },
          {
            type: "is",
            key: "employees_hotels.is_senior",
            value: this.isSenior,
          },
          {
            type: "gte",
            key: "employees_hotels.employees.shifts.date",
            value: start.toISOString(),
          },
          {
            type: "lte",
            key: "employees_hotels.employees.shifts.date",
            value: end.toISOString(),
          },
          {
            type: "eq",
            key: "employees_hotels.employees.status",
            value: "Aktiv",
          },
          {
            type: "is",
            key: "shifts.is_senior",
            value: this.isSenior,
          },
          {
            type: "gte",
            key: "employees_hotels.employees.employee_date_events.date",
            value: start.toISOString(),
          },
          {
            type: "neq",
            key: "employees_hotels.employees.status",
            value: "Inaktiv",
          },
          {
            type: "lte",
            key: "employees_hotels.employees.employee_date_events.date",
            value: end.toISOString(),
          },
          {
            type: "eq",
            key: "shifts.employees.employee_data_matching.key_id",
            value: 30,
          },
        ],
      });
      this.hotelObject = response.data[0];
      if (hotel) {
        this.chosenHotel.trainingShifts = hotel.trainingShifts
          ? hotel.trainingShifts
          : [];
      }

      this.shifts = this.hotelObject.shifts;
      this.createEmployeeShifts();
      this.createShiftPlan();
    },
    async changeDays(type) {
      this.isSenior = true;
      if (type === "plus") {
        this.today.setDate(this.today.getDate() + 7);
      } else {
        this.today.setDate(this.today.getDate() - 7);
      }
      this.startDate = new Date(this.today);
      this.endDate = new Date(this.today);
      this.startDate.setDate(this.today.getDate() - this.backDays);
      this.endDate.setDate(this.today.getDate() + this.forwardDays);
      this.shiftPlan.shifts = [];
      this.getProgress();
      if (this.hotelObject.id) {
        this.loadHotel();
        /*this.createEmployeeShifts()
        this.createShiftPlan();
        */
      }
    },
    async createShiftPlan() {
      this.shiftPlan.shifts = [];

      let start = new Date(this.startDate);
      let end = new Date(this.endDate);
      var days = this.backDays + this.forwardDays;
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "shifts",
        select: "*,employees(id,name,status)",
        where: [
          { type: "gte", key: "date", value: start.toISOString() },
          { type: "lte", key: "date", value: end.toISOString() },
          { type: "eq", key: "hotel_id", value: this.hotelObject.id },
          { type: "neq", key: "employees.status", value: "Inaktiv" },
          { type: "is", key: "is_senior", value: this.isSenior },
          { type: "is", key: "is_training", value: this.isTraining },
        ],
      });
      var shifts = response.data;
      var shiftDateObject = {};
      shifts.forEach((shift) => {
        var date = new Date(shift.date);
        shiftDateObject[date.toISOString().slice(0, 10)] = shift;
      });
      for (let i = 0; i <= days; i++) {
        var date = new Date(this.today);
        date.setDate(start.getDate() + i);
        var dateISO = date.toISOString().slice(0, 10);
        var weekday = this.weekdays[date.getDay()];

        if (this.isSenior) {
          var hotelBooking = this.hotelObject.shift_days_senior?.find(
            (day) => day === date.getDay(),
          );
        } else {
          var hotelBooking = this.hotelObject.shift_days_clerk?.find(
            (day) => day === date.getDay(),
          );
        }

        hotelBooking =
          hotelBooking >= 0 ||
          (shiftDateObject[dateISO] && shiftDateObject[dateISO].is_booked);
        this.shiftPlan.shifts.push({
          shiftInfo: shiftDateObject[dateISO],
          employeeId: shiftDateObject[dateISO]
            ? shiftDateObject[dateISO].employee_id
            : null,
          showSelection:
            (!shiftDateObject[dateISO] ||
              shiftDateObject[dateISO].employee_id == null) &&
            hotelBooking,
          date: date.toLocaleDateString() + ", " + weekday,
          dateDB: date.toISOString(),
          hotel_booking: hotelBooking,
          employee:
            shiftDateObject[dateISO] && shiftDateObject[dateISO].employees,
          employees: this.employeeRecord,
        });
      }
    },
    // Your methods go here
    createEmployeeShifts() {
      var employeeCheck = {};
      this.shifts.forEach((shift) => {
        if (shift.employee_id && !employeeCheck[shift.employee_id]) {
          employeeCheck[shift.employee_id] = {
            employee_id: shift.employee_id,
            status: shift.employees ? shift.employees.status : null,
            employee_name: shift.employees ? shift.employees.name : null,
            shift_days: shift.employees ? shift.employees.shift_days : null,
            weekly_hours:
              shift.employees && shift.employees.employee_data_matching[0]
                ? shift.employees.employee_data_matching[0].value_number
                : null,
            count: 1,
            availability_note:
              shift.employees && shift.employees.availability_note
                ? shift.employees.availability_note
                : null,
          };
          if (shift.is_senior) {
            employeeCheck[shift.employee_id].senior_count = 1;
            employeeCheck[shift.employee_id].clerk_count = 0;
            employeeCheck[shift.employee_id].training_count = 0;
          } else if (shift.is_senior == false && shift.is_training == false) {
            employeeCheck[shift.employee_id].senior_count = 0;
            employeeCheck[shift.employee_id].clerk_count = 1;
            employeeCheck[shift.employee_id].training_count = 0;
          } else if (shift.is_senior == false && shift.is_training == true) {
            employeeCheck[shift.employee_id].senior_count = 0;
            employeeCheck[shift.employee_id].clerk_count = 0;
            employeeCheck[shift.employee_id].training_count = 1;
          }
        } else if (shift.employee_id) {
          employeeCheck[shift.employee_id].count++;
          if (shift.is_senior) {
            employeeCheck[shift.employee_id].senior_count++;
          } else if (shift.is_senior == false && shift.is_training == false) {
            employeeCheck[shift.employee_id].clerk_count++;
          } else if (shift.is_senior == false && shift.is_training == true) {
            employeeCheck[shift.employee_id].training_count++;
          }
        }
      });

      this.employeeRecord = Object.values(employeeCheck);
      this.employeeRecord.forEach((emp) => {
        if (this.isSenior) {
          var toCount = emp.senior_count;
        } else {
          var toCount = emp.clerk_count;
        }
        if (toCount > 40) {
          emp.level = 5;
        } else if (toCount > 30) {
          emp.level = 4;
        } else if (toCount > 20) {
          emp.level = 3;
        } else if (toCount > 10) {
          emp.level = 2;
        } else if (toCount > 0) {
          emp.level = 1;
        } else {
          emp.level = 0;
        }
        this.addExistingShifts(emp);
        this.addFreeDays(emp);
      });
      this.employeeRecord.sort((a, b) => b.count - a.count);
    },
    getAllEmployees() {
      this.allEmployees = [];
      this.$store
        .dispatch("getSupabaseEntity", {
          table: "employees",
          select: "name,id,status",
          where: [{ type: "eq", key: "status", value: "Aktiv" }],
        })
        .then((response) => {
          response.data.forEach((employee) => {
            employee.employee_name =
              employee.last_name + ", " + employee.first_name;
          });
          this.allEmployees = response.data;
        });
    },
    addExistingShifts(employee) {
      employee.shifts = [];
      var checkShifts = this.hotelObject.employees_hotels.filter(
        (employeeCheck) => employeeCheck.employee_id == employee.employee_id,
      )[0];
      if (checkShifts && checkShifts.employees) {
        checkShifts = checkShifts.employees.shifts;
      } else {
        checkShifts = [];
      }
      checkShifts.forEach((shift) => {
        var weekday = this.weekdays[new Date(shift.date).getDay()];
        shift.date = new Date(shift.date).toLocaleDateString() + ", " + weekday;
        employee.shifts.push(shift.date);
      });
    },
    addFreeDays(employee) {
      employee.free_days = [];
      employee.holidays = [];
      employee.is_free_wish = [];
      employee.is_office_shift = [];
      var checkFreeDays = this.hotelObject.employees_hotels.filter(
        (employeeCheck) => employeeCheck.employee_id == employee.employee_id,
      )[0];
      if (checkFreeDays && checkFreeDays.employees) {
        checkFreeDays = checkFreeDays.employees.employee_date_events;
      } else {
        checkFreeDays = [];
      }
      checkFreeDays.forEach((freeDays) => {
        var weekday = this.weekdays[new Date(freeDays.date).getDay()];
        freeDays.date =
          new Date(freeDays.date).toLocaleDateString() + ", " + weekday;
        if (freeDays.is_holiday) {
          employee.holidays.push(freeDays.date);
        } else if (freeDays.is_free) {
          employee.free_days.push(freeDays.date);
        } else if (freeDays.is_free_wish) {
          employee.is_free_wish.push(freeDays.date);
        } else if (freeDays.is_office_shift) {
          employee.is_office_shift.push(freeDays.date);
        }
      });
    },
    getNextMonday() {
      this.today = new Date();
      const currentDay = this.today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
      const daysUntilNextMonday = (8 - currentDay) % 7 || 7; // Calculate days until next Monday
      this.today.setDate(this.today.getDate() + daysUntilNextMonday); // Add days to get to the next Monday
      this.startDate = new Date(this.today);
      this.endDate = new Date(this.today);
      this.startDate.setDate(this.today.getDate() - this.backDays);
      this.endDate.setDate(this.today.getDate() + this.forwardDays);
    },
  },
  watch: {
    /*selectedHotel(newVal, oldVal) {
      if (newVal) {
        this.loadHotel();
      }
    },*/
  },

  mounted() {
    this.getNextMonday();
    this.getProgress();
    this.getAllEmployees();
    // Code to run when the component is mounted goes here
  },
};
</script>

<style scoped>
/* Your component-specific styles go here */
</style>
